import React, { useState } from 'react';
import * as R from 'ramda';
import {
  alwaysNewDate,
  roundTo,
  ensureIsDate,
  propEqLegacy,
} from '@poly/utils';
import { startOfYear, addMonths, startOfDay, endOfDay } from 'date-fns';
import { gql } from '@apollo/client';
import styled from 'styled-components';

import { DatePicker, Select, Loader, NumberInputHTML5 } from '@poly/admin-book';
import {
  TableCardWithPaginationContainer,
  useSetItemsCount,
} from '@poly/admin-ui';
import { useSelector } from 'react-redux';
import {
  paginationToQueryParams,
  useReactiveQuery,
  useSortableTable,
} from '@poly/client-utils';
import {
  PageWithSearchHeader,
  SearchPageContentWrapper,
  SearchPageHeaderContainer,
} from '../../components/PageWithSearchHeader.js';
import { SearchHeaderCreator } from '../../components/SearchHeaderCreator.js';
import {
  SearchTransactionsTable,
  transactionsSearchTableConfig,
} from './SearchTransactionsTable.js';
import { JOURNAL_CHANGED_SUBSCRIPTION } from '../../sidebars/TransactionDetailsSidebar/TransactionDetailsSidebar.js';

const ONE_YEAR_RANGE_VALUE = 'oneYear';
const TWO_YEARS_RANGE_VALUE = 'twoYears';
const THIS_YEAR_RANGE_VALUE = 'thisYear';
const CUSTOM_RANGE_VALUE = 'customRange';

const dateRangeOptions = [
  { value: THIS_YEAR_RANGE_VALUE, label: 'This Year' },
  { value: ONE_YEAR_RANGE_VALUE, label: '12 Months' },
  { value: TWO_YEARS_RANGE_VALUE, label: '24 Months' },
  { value: CUSTOM_RANGE_VALUE, label: 'Custom' },
];

const monthSubtractionsMap = {
  [ONE_YEAR_RANGE_VALUE]: 12,
  [TWO_YEARS_RANGE_VALUE]: 24,
};

const PageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;

// transformStartDateByRangeSelect :: Date -> (String, Date) -> Date
const transformStartDateByRangeSelect =
  (currentDate) => (dateRange, currentValue) => {
    if (dateRange === THIS_YEAR_RANGE_VALUE) {
      return startOfYear(ensureIsDate(currentDate));
    }
    if (dateRange === CUSTOM_RANGE_VALUE) {
      return currentValue;
    }
    return addMonths(
      ensureIsDate(currentDate),
      -monthSubtractionsMap[dateRange],
    );
  };

const searchTransactionsHeaderConfig = (currentDate) => [
  {
    key: 'amountRow',
    columns: [
      {
        name: 'amount',
        title: 'Transaction Amount',
        filterProps: { width: '100%', changeOnBlur: true },
        column: 1,
        Component: NumberInputHTML5,
      },
    ],
  },
  {
    key: 'dateRow',
    rowLayout: { justifyContent: 'flex-start' },
    columns: [
      {
        name: 'startDate',
        defaultValue: addMonths(ensureIsDate(currentDate), -12),
        title: 'From',
        filterProps: { width: '100%' },
        disabledIf: R.complement(propEqLegacy('dateRange', CUSTOM_RANGE_VALUE)),
        column: 1,
        Component: DatePicker,
      },
      {
        name: 'endDate',
        defaultValue: currentDate,
        title: 'To',
        columnLayout: { titleWidth: '50px' },
        filterProps: { width: '100%' },
        disabledIf: R.complement(propEqLegacy('dateRange', CUSTOM_RANGE_VALUE)),
        column: 2,
        Component: DatePicker,
      },
      {
        name: 'dateRange',
        defaultValue: ONE_YEAR_RANGE_VALUE,
        filterProps: {
          width: '100%',
          options: dateRangeOptions,
          required: true,
        },
        subscribers: [
          ['startDate', transformStartDateByRangeSelect(currentDate)],
        ],
        column: 3,
        Component: Select,
      },
    ],
  },
];

const searchTransactionsQuery = gql`
  query searchTransactions($input: SearchTransactionsInput!) {
    searchTransactions(input: $input) {
      hits {
        transactionNumber
        amount
        date
        type
        account {
          _id
          name
        }
        supplier {
          _id
          company {
            name
          }
        }
        client {
          _id
          name
        }
        createdBy {
          _id
          profile {
            fullName
          }
        }
      }
      total
    }
  }
`;

const formatTransactionsSearchInput = R.compose(
  R.evolve({
    amount: R.compose(roundTo(2), R.constructN(1, Number)),
    startDate: R.compose(startOfDay, ensureIsDate),
    endDate: R.compose(endOfDay, ensureIsDate),
  }),
  R.omit(['dateRange']),
);

export function SearchTransactionsPage() {
  const [filters, setFilters] = useState(null);
  const pagination = useSelector(R.prop('pagination'));

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: transactionsSearchTableConfig,
  });

  const { data, loading } = useReactiveQuery(
    searchTransactionsQuery,
    JOURNAL_CHANGED_SUBSCRIPTION,
    {
      queryOptions: {
        variables: {
          input: {
            sort,
            ...formatTransactionsSearchInput(filters),
            ...paginationToQueryParams(pagination),
          },
        },
        skip: !filters,
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: {
        ...(filters
          ? {
              variables: {
                input: {
                  query: {
                    bool: {
                      must: {
                        range: {
                          date: {
                            gte: filters.startDate,
                            lte: filters.endDate,
                          },
                        },
                      },
                    },
                  },
                },
              },
            }
          : { skip: true }),
      },
    },
  );

  useSetItemsCount(R.pathOr(0, ['searchTransactions', 'total']), data);

  const transactions = R.pathOr([], ['searchTransactions', 'hits'], data);

  return (
    <PageWrapper>
      <PageWithSearchHeader headerHeight="144px">
        <SearchPageHeaderContainer title="Transaction Amount Search">
          <SearchHeaderCreator
            config={searchTransactionsHeaderConfig(alwaysNewDate())}
            loading={loading}
            onSearch={setFilters}
          />
        </SearchPageHeaderContainer>
        <SearchPageContentWrapper>
          <TableCardWithPaginationContainer>
            {loading ? (
              <Loader />
            ) : (
              <SearchTransactionsTable
                transactions={transactions}
                {...tableSortProps}
              />
            )}
          </TableCardWithPaginationContainer>
        </SearchPageContentWrapper>
      </PageWithSearchHeader>
    </PageWrapper>
  );
}
