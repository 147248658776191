import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { string, bool, arrayOf, shape } from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { extractTablePropsFromConfig, useTableSorting } from '@poly/admin-ui';
import { keywordSortQuery, commonSortQuery } from '@poly/client-utils';
import { FULL_ACCESS_PERMISSION } from '@poly/security';
import {
  centsToDollarsWithFormat,
  propEqLegacy,
  formatTotal,
  applyOr,
} from '@poly/utils';
import {
  DefaultBodyWrapper,
  defaultTheme,
  TableCard,
  BodyRow,
  Loader,
  Status,
  Table,
} from '@poly/admin-book';
import {
  NOTHING_UI_STRING,
  InvoicesStatuses,
  ASC_SORT_ORDER,
} from '@poly/constants';

import { MixedBodyRow } from './MixedBodyRow.js';
import { ClickableRow } from './ClickableRow.js';
import { ApprovePostSupplierInvoicesTabsMap } from './constants.js';
import { setPreviewIds } from '../../redux/requestedSupplierDocumentsReducer.js';
import { useSidebarLogicContext } from '../../sidebars/SidebarLogicContext.js';
import { setEntitySortAction } from '../../redux/entitySort/entitySort.js';
import { useApproveInvoices } from './useApproveInvoices.js';

const { TO_REVIEW, SUBMITTED_BY_ME } = ApprovePostSupplierInvoicesTabsMap;

const {
  colors: {
    statuses: { active, error },
  },
} = defaultTheme;

const TableS = styled(Table)`
  ${({ isPrint }) => isPrint && 'margin-top: 30px'};

  td:nth-child(1),
  th:nth-child(1) {
    width: 25px;
    padding-left: 0;
    padding-top: 15px;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 20%;
  }
  td:nth-child(6),
  th:nth-child(6),
  td:nth-child(7),
  th:nth-child(7) {
    width: 150px;
  }
`;

function InvoicePropertyLink(invoice) {
  const { PropertyLink } = useSidebarLogicContext();

  const property = R.path(['project', 'property'], invoice);

  if (!property) {
    return NOTHING_UI_STRING;
  }
  return <PropertyLink {...property} />;
}

function InvoiceClientLink(invoice) {
  const { ClientLink } = useSidebarLogicContext();

  const client = invoice?.client;

  if (!client) {
    return NOTHING_UI_STRING;
  }

  return <ClientLink {...client} />;
}

function InvoiceProjectLink(invoice) {
  const { ProjectLink } = useSidebarLogicContext();

  const project = R.path(['project'], invoice);

  if (!project) {
    return NOTHING_UI_STRING;
  }
  return <ProjectLink skipOpen {...project} />;
}

function InvoiceStatusCircle({ status }) {
  const color = status === InvoicesStatuses.REJECTED ? error : active;
  return <Status color={color} />;
}

InvoiceStatusCircle.propTypes = {
  status: string.isRequired,
};

// getSupplierNte :: Project -> Money
const getSupplierNte = R.converge(R.compose(R.prop('nte'), R.find), [
  R.compose(R.propEq(R.__, '_id'), R.path(['supplier', '_id'])),
  R.compose(R.pathOr([], ['project', 'suppliers'])),
]);

const approveInvoicesTableConfig = (isPrint) => [
  ['', InvoiceStatusCircle],
  [
    'Project',
    isPrint ? R.path(['project', 'projectId']) : InvoiceProjectLink,
    keywordSortQuery(['project', 'projectId']),
  ],
  [
    'Supplier',
    R.path(['supplier', 'company', 'name']),
    keywordSortQuery(['supplier', 'company', 'name']),
  ],
  ['Property', InvoicePropertyLink, keywordSortQuery(['property', 'name'])],
  ['Client', InvoiceClientLink, keywordSortQuery(['client', 'name'])],
  [
    // eslint-disable-next-line @cspell/spellchecker
    'SNTE',
    R.compose(applyOr(formatTotal, NOTHING_UI_STRING), getSupplierNte),
    commonSortQuery(['supplierNTE']),
  ],
  [
    'Inv Amount',
    R.compose(centsToDollarsWithFormat, R.prop('total')),
    commonSortQuery(['total']),
  ],
];

const getRowComponent = R.cond([
  [
    R.either(
      propEqLegacy('tab', TO_REVIEW),
      R.both(
        R.prop('isUserWithFullAccess'),
        propEqLegacy('tab', SUBMITTED_BY_ME),
      ),
    ),
    R.always(ClickableRow),
  ],
  [
    R.either(propEqLegacy('tab', SUBMITTED_BY_ME), R.prop('isPrint')),
    R.always(BodyRow),
  ],
  // next case for "All" tab
  [R.T, R.always(MixedBodyRow)],
]);

// getApproveInvoicesIds :: Boolean -> [Invoice] -> [ID]
const getApproveInvoicesIds = (isUserWithFullAccess) =>
  R.compose(
    R.pluck('_id'),
    R.unless(R.always(isUserWithFullAccess), R.reject(R.prop('isOwnInvoice'))),
    R.defaultTo([]),
  );

export function ApproveInvoicesTableComponent({
  invoices,
  isPrint,
  loading,
  ...props
}) {
  const { columns, headers, sortQueries } = extractTablePropsFromConfig(
    approveInvoicesTableConfig(isPrint),
  );

  const RowComponent = getRowComponent({ isPrint, ...props });

  return (
    <TableCard>
      {loading ? (
        <Loader />
      ) : (
        <TableS
          showScrollBar
          overflow="auto"
          rows={invoices}
          isPrint={isPrint}
          columns={columns}
          headers={headers}
          sortQueries={sortQueries}
          RowComponent={RowComponent}
          {...props}
        />
      )}
    </TableCard>
  );
}

ApproveInvoicesTableComponent.propTypes = {
  isPrint: bool,
  loading: bool,
  invoices: arrayOf(shape({ _id: string.isRequired })).isRequired,
};

export function ApproveInvoicesTable({
  tab,
  userId,
  includeUserId,
  excludeUserId,
}) {
  const dispatch = useDispatch();

  const isUserWithFullAccess = useHasUserAccessWithPermission(
    FULL_ACCESS_PERMISSION,
  );

  const { sort, ...tableSortProps } = useTableSorting({
    column: 5,
    tableConfig: approveInvoicesTableConfig(),
    defaultSort: commonSortQuery(['createdAt'])(ASC_SORT_ORDER),
  });

  const { invoices, refetch, tableProps, loading } = useApproveInvoices({
    sort,
    userId,
    includeUserId,
    excludeUserId,
  });

  useEffect(() => {
    dispatch(
      setPreviewIds(getApproveInvoicesIds(isUserWithFullAccess)(invoices)),
    );
  }, [invoices]);

  useEffect(() => {
    dispatch(setEntitySortAction(sort));

    return () => {
      dispatch(setEntitySortAction(null));
    };
  }, [sort]);

  return (
    <DefaultBodyWrapper>
      <ApproveInvoicesTableComponent
        tab={tab}
        loading={loading}
        invoices={invoices}
        isUserWithFullAccess={isUserWithFullAccess}
        tableRowProps={{ refetch, isUserWithFullAccess }}
        {...tableSortProps}
        {...tableProps}
      />
    </DefaultBodyWrapper>
  );
}

ApproveInvoicesTable.propTypes = {
  tab: string,
  userId: string,
  includeUserId: string,
  excludeUserId: string,
};
