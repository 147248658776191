import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from '@poly/constants';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';

export const FlexCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${R.propOr('0px', 'gap')};
`;

export function PrintExportButtons({
  handlePrintPDF,
  handleExportXLS,
  loading,
}) {
  return (
    <FlexCenterWrapper>
      <LinkButton onClick={handlePrintPDF} disabled={loading}>
        {PRINT_PDF_CAPTION}
      </LinkButton>
      <ToolBarBtnDivider />
      <LinkButton onClick={handleExportXLS} disabled={loading}>
        {EXPORT_XLS_CAPTION}
      </LinkButton>
    </FlexCenterWrapper>
  );
}

PrintExportButtons.propTypes = {
  handlePrintPDF: func.isRequired,
  handleExportXLS: func.isRequired,
  loading: bool,
};
