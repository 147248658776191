import React from 'react';
import { arrayOf, oneOfType, shape, string } from 'prop-types';
import { ClientStatuses } from '@poly/constants';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';

import { useClientsLazyQuery } from '../Clients/useClientsLazyQuery.js';
import { getXlsClientsConfig } from '../Clients/getXlsClientsConfig.js';
import { ClientsPrintTable } from '../../modules/tables/clientsTable/ClientsTable.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';

export function ProspectsExportButtons({ sort }) {
  const { runClientsQuery, loading } = useClientsLazyQuery({
    sort,
    status: ClientStatuses.PROSPECT,
  });

  const handlePrintPDF = async () => {
    const clients = await runClientsQuery();

    await openPrintWindowWithData({
      fileName: 'prospects_export.pdf',
      Layout: CommonPrintLayout,
      Table: ClientsPrintTable,
      metaData: {
        title: 'Prospects',
      },
      clients,
    });
  };

  const handleExportXLS = async () => {
    const clients = await runClientsQuery();

    const exportConfig = getXlsClientsConfig({
      clients,
      exportFileName: 'prospects_export',
      withStatus: false,
    });
    return performExcelExport(exportConfig);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}

ProspectsExportButtons.propTypes = {
  sort: arrayOf(oneOfType([string, shape({})])),
};
