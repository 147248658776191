import * as R from 'ramda';
import { func, bool } from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { startOfDay, endOfDay } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { ensureIsDate, assocBy, ofArrayLegacy } from '@poly/utils';
import { DatePicker, NumberInputHTML5 } from '@poly/admin-book';
import { useRouterQuery } from '@poly/client-routing';
import {
  MasterSupplierSelect,
  SupplierSelect,
  MAX_ITEMS,
} from '@poly/admin-ui';

import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { JustifyFlexEnd } from '../../components/FlexContainer.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const suppliersIdsQuery = gql`
  query suppliersIdsQuery($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
      }
    }
  }
`;

// voidSupplierPaymentsFilters :: VoidSupplierPaymentsFilters
const voidSupplierPaymentsFilters = {
  supplierId: 'supplierId',
  masterSupplierId: 'masterSupplierId',
  checkNumber: 'checkNumber',
  checkFromDate: 'checkFromDate',
  checkToDate: 'checkToDate',
};

// dissocPropIfEmpty :: k -> {k: Any} -> {k: Any}
//   k = String
const dissocPropIfEmpty = (prop) =>
  R.when(R.propSatisfies(R.isEmpty, prop), R.dissoc(prop));

const voidSupplierInvoiceHeaderConfig = [
  { name: voidSupplierPaymentsFilters.supplierId, defaultValue: null },
  { name: voidSupplierPaymentsFilters.masterSupplierId, defaultValue: null },
  { name: voidSupplierPaymentsFilters.checkNumber, defaultValue: '' },
  { name: voidSupplierPaymentsFilters.checkFromDate, defaultValue: '' },
  { name: voidSupplierPaymentsFilters.checkToDate, defaultValue: '' },
];

// constructAndAdjustDate :: (Date -> Date, String) -> Object -> Object
export const constructAndAdjustDate = (adjustDateFn, prop) =>
  R.over(
    R.lensProp(prop),
    R.when(R.is(String), R.compose(adjustDateFn, R.constructN(1, Date))),
  );

// isMasterSelectedWithoutBranches :: VoidSupplierPaymentsFilters -> Boolean
const isMasterSelectedWithoutBranches = R.both(
  R.propSatisfies(R.complement(R.isNil), 'masterSupplierId'),
  R.propSatisfies(R.isNil, 'supplierId'),
);

// generateSearchVoidPaymentsQuery :: [ID] -> VoidSupplierPaymentsFilters -> JournalPaymentBatchQuery
const generateSearchVoidPaymentsQuery = (supplierIds) =>
  R.compose(
    R.ifElse(
      isMasterSelectedWithoutBranches,
      R.assoc('supplierIds', supplierIds),
      assocBy(
        'supplierIds',
        R.compose(R.reject(R.isNil), ofArrayLegacy, R.prop('supplierId')),
      ),
    ),
    R.when(
      R.has('checkNumber'),
      R.over(R.lensProp('checkNumber'), (s) => parseInt(s, 10)),
    ),
    constructAndAdjustDate(
      R.compose(startOfDay, ensureIsDate),
      'checkFromDate',
    ),
    constructAndAdjustDate(R.compose(endOfDay, ensureIsDate), 'checkToDate'),
    dissocPropIfEmpty('masterSupplierId'),
    dissocPropIfEmpty('supplierId'),
    dissocPropIfEmpty('checkNumber'),
    R.defaultTo({}),
  );

// getSupplierIds :: SuppliersSearchResult -> [ID]
const getSupplierIds = R.compose(
  R.map(R.prop('_id')),
  R.pathOr([], ['searchSuppliers', 'hits']),
);

export function VoidSupplierPaymentsHeader({ setQuery, loading }) {
  const { masterSupplierId } = useRouterQuery(['masterSupplierId']);

  const suppliersQuery = masterSupplierId
    ? { term: { masterSupplierId } }
    : null;

  const { data } = useQuery(suppliersIdsQuery, {
    variables: {
      searchInput: {
        from: 0,
        size: MAX_ITEMS,
        query: suppliersQuery,
      },
    },
    skip: !masterSupplierId,
  });

  const supplierIds = useMemo(() => getSupplierIds(data), [data]);

  const onSearchHandler = (values) =>
    setQuery(generateSearchVoidPaymentsQuery(supplierIds)(values));

  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    voidSupplierInvoiceHeaderConfig,
    onSearchHandler,
  );

  useEffect(() => {
    handlers.supplierId('');
  }, [searchFilters.masterSupplierId]);

  const onResetSearch = () => {
    onReset();
    setQuery({});
  };

  return (
    <SearchPageHeaderContainer title="Void Supplier Payments">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Master Supplier" titleWidth="160px">
            <MasterSupplierSelect
              width="100%"
              isClearable
              onChange={handlers.masterSupplierId}
              value={searchFilters.masterSupplierId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Supplier">
            <SupplierSelect
              value={searchFilters.supplierId}
              onChange={handlers.supplierId}
              query={suppliersQuery}
              withoutSkip={!!searchFilters.masterSupplierId}
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Check No">
            <NumberInputHTML5
              placeholder="Enter Check Number"
              maxLength={7}
              width="100%"
              value={searchFilters.checkNumber}
              onChange={handlers.checkNumber}
              name="checkNumber"
              changeOnBlur
            />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Payment Date From" titleWidth="160px">
            <DatePicker
              value={searchFilters.checkFromDate}
              onChange={handlers.checkFromDate}
              width="100%"
              showResetBtn
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Payment Date To" margin="0">
            <DatePicker
              value={searchFilters.checkToDate}
              onChange={handlers.checkToDate}
              width="100%"
              showResetBtn
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="390px">
            <JustifyFlexEnd>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                loader={loading}
                size="small"
                onClick={onSearch}
              >
                Search
              </SearchHeaderButton>
            </JustifyFlexEnd>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

VoidSupplierPaymentsHeader.propTypes = {
  setQuery: func.isRequired,
  loading: bool.isRequired,
};
