import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  arrayOf,
  bool,
  element,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { centsToDollarsWithFormat } from '@poly/utils/src/converters.js';
import { insertParamsIntoURL } from '@poly/utils/src/url.js';
import { Link } from '@poly/client-routing/src/generator.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { useSelectableTableRowsProps } from '@poly/admin-ui/src/hooks/useSelectableTableRowsProps.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { duplicateField } from '@poly/client-utils/src/objects.js';
import { keywordSortQuery } from '@poly/client-utils/src/sorting.js';
import { useHighlightMatchesBySearch } from '@poly/client-utils/src/hooks/useHighlightMatchesBySearch.js';
import { IconButton } from '@poly/admin-book/src/IconButton/index.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';

import { AssetManufacturersForm } from './AssetManufacturersForm.js';
import { editAssetManufacturerFormId } from './constants.js';
import { routesNames } from '../../routes/constants.js';

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(4),
  td:nth-child(4) {
    width: 40px;
  }
`;

function AssetManufacturerModelsLink({ _id, nameUI }) {
  const assetModelsHref = insertParamsIntoURL({ manufacturerId: _id })(
    routesNames.ASSET_MANUFACTURER_MODELS,
  );
  return <Link href={assetModelsHref}>{nameUI}</Link>;
}

AssetManufacturerModelsLink.propTypes = {
  _id: string.isRequired,
  nameUI: oneOfType([string, element, arrayOf(oneOfType([string, element]))]),
};

function EditButton({ refetch, ...props }) {
  const { openModalForm } = useModalContext();
  const onClick = () =>
    openModalForm({
      id: editAssetManufacturerFormId,
      title: 'Edit Manufacturer',
      formId: editAssetManufacturerFormId,
      btnCaption: 'Save',
      content: (
        <AssetManufacturersForm
          isNewManufacturer={false}
          document={props}
          refetch={refetch}
        />
      ),
    });

  return <IconButton size={15} name="edit" onClick={onClick} />;
}

EditButton.propTypes = {
  refetch: func.isRequired,
};

const assetReplacementCostColumnConfig = [
  'Asset Replacement Cost',
  R.compose(centsToDollarsWithFormat, R.prop('replacementCost')),
];

export const assetManufacturersTableConfig = [
  ['Name', AssetManufacturerModelsLink, keywordSortQuery(['name'])],
  assetReplacementCostColumnConfig,
  ['', EditButton],
];

const assetManufacturersPrintTableConfig = [
  ['Name', R.prop('name')],
  assetReplacementCostColumnConfig,
];

export function AssetManufacturersPrintTable({ assetManufacturers }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    assetManufacturersPrintTableConfig,
    assetManufacturers,
  );
  return <Table {...tableProps} />;
}

AssetManufacturersPrintTable.propTypes = {
  assetManufacturers: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      replacementCost: number,
    }),
  ),
};

export function AssetManufacturersPageTable({
  loading,
  selectedRows,
  setSelectedRows,
  assetManufacturers,
  ...tableSortingProps
}) {
  const { rows, ...tableProps } = useMapConfigToTableProps(
    R.identity,
    assetManufacturersTableConfig,
    assetManufacturers,
  );

  const { toggleRow } = useSelectableTableRowsProps(
    selectedRows,
    setSelectedRows,
    assetManufacturers,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.map(duplicateField('name', 'nameUI')),
    [['nameUI']],
    rows,
  );

  return loading ? (
    <Loader />
  ) : (
    <TableS
      {...tableProps}
      {...tableSortingProps}
      isLoading={loading}
      showScrollBar
      isPartiallySelectable
      selectedRows={selectedRows}
      isRowSelectable={R.T}
      toggleRow={toggleRow}
      rows={highlightedRows}
    />
  );
}

AssetManufacturersPageTable.propTypes = {
  loading: bool,
  selectedRows: arrayOf(string),
  setSelectedRows: func,
  assetManufacturers: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
};
