import { NotFoundPage, VendorInfoPage } from '@poly/admin-ui';

import { MainLayout } from '../layouts/MainLayout.js';
import { PublicLayout } from '../layouts/PublicLayout.js';
import { EmptyLayout } from '../layouts/EmptyLayout.js';

import { routesNames } from './constants.js';
import { HomePage } from '../pages/HomePage/index.js';
import { LoginPage } from '../pages/LoginPage.js';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage.js';
import { MasterProjectsPage } from '../pages/MasterProjectsDirectory/MasterProjectsPage.js';
import { MentionGroupsPage } from '../pages/MentionGroupsPage.js';
import { MyProjectsPage } from '../pages/MyProjectsPage/MyProjectsPage.js';
import { ClientsPage } from '../pages/Clients/ClientsPage.js';
import { AccountIndexPage } from '../pages/AccountIndexPage/AccountIndexPage.js';
import { AddSupplierPage } from '../pages/AddSupplierPage.js';
import { AdminSettingsPage } from '../pages/AdminSettingsPage.js';
import { EmergencyProjectsPage } from '../pages/EmergencyProjects/EmergencyProjectsPage.js';
import { MyEmergencyProjectsPage } from '../pages/MyEmergencyProjectsPage.js';
import { MyMasterProjectsPage } from '../pages/MyMasterProjectsPage.js';
import { TimeSheetReportsPage } from '../pages/TimeSheetReportsPage.js';
import { TimesheetPage } from '../pages/TimesheetPage/TimesheetPage.js';
import { RecalledProjectsPage } from '../pages/RecalledProjectsPage.js';
import { SpendReportsPage } from '../pages/SpendReport/SpendReportsPage.js';
import { PaySuppliersPage } from '../pages/PaySuppliersPage/PaySuppliersPage.js';
import { EnterSupplierInvoicePage } from '../pages/EnterSupplierInvoicePage.js';
import { SuppliersMapPage } from '../pages/SuppliersMapPage.js';
import { TimeSheetReportPage } from '../pages/TimeSheetReportPage.js';
import { SuppliersSubcontractorPage } from '../pages/SuppliersSubcontractorPage.js';
import { SupplierSearchPage } from '../pages/SupplierSearchPage.js';
import { SuppliersAdminAACPage } from '../pages/SuppliersAdminAACPage.js';
import { SupplierPage } from '../pages/SupplierPage/SupplierPage.js';
import { StaffDirectoryPage } from '../pages/StaffDirectoryPage.js';
import { ServiceTypesManagementPage } from '../pages/ServiceTypes/ServiceTypesManagementPage.js';
import { ResetPasswordPage } from '../pages/ResetPasswordPage.js';
import { ReportsPage } from '../pages/ReportsPage.js';
import { RecentProjectReportPage } from '../pages/RecentProjectsPage.js';
import { ProspectsPage } from '../pages/Prospects/ProspectsPage.js';
import { ProjectsPage } from '../pages/ProjectsDirectory/ProjectsPage.js';
import { UnassignedProjectsPage } from '../pages/UnassignedProjectsPage/UnassignedProjectsPage.js';
import { MyTasksPage } from '../pages/MyTasksPage/MyTasksPage.js';
import { PrintChecksPage } from '../pages/PrintChecksPage/PrintChecksPage.js';
import { SupplierPaymentHistoryPage } from '../pages/SupplierPaymentHistoryPage/SupplierPaymentHistoryPage.js';
import { PrintChecksDetailsPage } from '../pages/PrintChecksDetailsPage/PrintChecksDetailsPage.js';
import { UserSettingsPage } from '../pages/UserSettings/UserSettingsPage.js';
import { SearchSupplierInvoicesPage } from '../pages/SearchSupplierInvoices/SearchSupplierInvoicesPage.js';
import { EditSupplierInvoicePage } from '../pages/EditSupplierInvoicePage.js';
import { AccountTypesPage } from '../pages/AccountTypes/AccountTypesPage.js';
import { DivisionsPage } from '../pages/Divisions/DivisionsPage.js';
import { ChartOfAccountsPage } from '../pages/ChartOfAccounts/ChartOfAccountsPage.js';
import { VoidSupplierPaymentsPage } from '../pages/VoidSupplierPayments/VoidSupplierPaymentsPage.js';
import { GeneralLedgerPage } from '../pages/GeneralLedgerPage/GeneralLedgerPage.js';
import { AccountTransactionsPage } from '../pages/AccountTransactionsPage/AccountTransactionsPage.js';
import { CreditCardRegistersPage } from '../pages/CreditCardRegistersPage/CreditCardRegistersPage.js';
import { BankRegistersPage } from '../pages/BankRegistersPage/BankRegistersPage.js';
import { BankReconciliationPage } from '../pages/BankReconciliation/BankReconciliationPage.js';
import { BankDepositsPage } from '../pages/BankDeposits/BankDepositsPage.js';
import { CreateJournalEntryPage } from '../pages/JournalEntry/CreateJournalEntryPage.js';
import { JournalEntryLedgerPage } from '../pages/JournalEntry/JournalEntryLedgerPage.js';
import { AccountsReceivableReportPage } from '../pages/AccountsReceivableReport/AccountsReceivableReportPage.js';
import { AccountsPayableReportPage } from '../pages/AccountsPayableReport/AccountsPayableReportPage.js';
import { HouseKeepingServiceTicketsReportPage } from '../pages/HouseKeepingServiceTicketsReportPage/HouseKeepingServiceTicketsReportPage.js';
import { HousekeepingBatchInvoicingPage } from '../pages/BatchInvoicingHousekeeping/HousekeepingBatchInvoicingPage.js';
import { BatchInvoicingTMBassProPage } from '../pages/BatchInvoicingTM/BatchInvoicingTMBassProPage.js';
import { ClientInvoiceHistoryPage } from '../pages/ClientInvoiceHistory/ClientInvoiceHistoryPage.js';
import { APAgingReportPage } from '../pages/APAgingReport/APAgingReportPage.js';
import { BatchInvoiceReportsPage } from '../pages/BatchInvoiceReports/BatchInvoiceReportsPage.js';
import { BatchInvoiceReportsDetailsPage } from '../pages/BatchInvoiceReportsDetails/BatchInvoiceReportsDetailsPage.js';
import { ARAgingReportPage } from '../pages/ARAgingReport/ARAgingReportPage.js';
import { WorkInProgressReportPage } from '../pages/WorkInProgressReport/WorkInProgressReportPage.js';
import { CreditCardReconciliationPage } from '../pages/CreditCardReconciliation/CreditCardReconciliationPage.js';
import { SupplierApReportPage } from '../pages/SupplierApReportPage/SupplierApReportPage.js';
import { BalanceSheetPage } from '../pages/BalanceSheet/BalanceSheetPage.js';
import { ProfitLossReportPage } from '../pages/ProfitLossReportPage/ProfitLossReportPage.js';
import { SearchTransactionsPage } from '../pages/SearchTransactionsPage/SearchTransactionsPage.js';
import { TransferFundsPage } from '../pages/TransferFundsPage/TransferFundsPage.js';
import { BankReconciliationReportPage } from '../pages/BankReconciliationReport/BankReconciliationReportPage.js';
import { CreditCardReconciliationReportPage } from '../pages/CreditCardReconciliationReportPage.js';
import { CreditCardUsersPage } from '../pages/CreditCardUsers/CreditCardUsersPage.js';
import { PayByBankPage } from '../pages/PayByFormPage/PayByBankPage.js';
import { PayByCreditCardPage } from '../pages/PayByFormPage/PayByCreditCardPage.js';
import { TrialBalancePage } from '../pages/TrialBalancePage/TrialBalancePage.js';
import { StaffReportPage } from '../pages/StaffReport/StaffReportPage.js';
import { StaffReportDetailsPage } from '../pages/StaffReportDetails/StaffReportDetailsPage.js';
import { InputPayrollPage } from '../pages/InputPayrollPage/InputPayrollPage.js';
import { SearchClientInvoicesPage } from '../pages/SearchClientInvoices/SearchClientInvoicesPage.js';
import { ClientHistoryPage } from '../pages/ClientHistoryPage/ClientHistoryPage.js';
import { CreateClientInvoiceSearchPage } from '../pages/CreateClientInvoice/CreateClientInvoiceSearchPage.js';
import { CreateClientInvoicePage } from '../pages/CreateClientInvoice/CreateClientInvoicePage.js';
import { EditClientInvoicePage } from '../pages/CreateClientInvoice/EditClientInvoicePage.js';
import { AddMasterSupplierPage } from '../pages/MasterSupplier/AddMasterSupplierPage.js';
import { MasterSupplierPage } from '../pages/MasterSupplier/MasterSupplierPage.js';
import {
  MasterSuppliersAdministrativePage,
  MasterSuppliersSubcontractorsPage,
} from '../pages/MasterSupplier/MasterSuppliersPage.js';
import { AddBranchSupplierPage } from '../pages/BranchSupplier/AddBranchSupplierPage.js';
import { SupplierAttachServiceTicketFilePage } from '../pages/SupplierAttachServiceTicketFile/SupplierAttachServiceTicketFilePage.js';
import { MasterSupplierAPReportPage } from '../pages/SupplierApReportPage/MasterSupplierAPReportPage.js';
import { CloseFinancialYearPage } from '../pages/CloseFinancialYearPage/CloseFinancialYearPage.js';
import { ClosingFinancialYearJournalPage } from '../pages/CloseFinancialYearPage/ClosingFinancialYearJournalPage.js';
import { BatchInvoicingBidBassProPage } from '../pages/BatchInvoicingBid/BatchInvoicingBidPage.js';
import { ReopenAccountingPeriod } from '../pages/ReopenAccountingPeriod/ReopenAccountingPeriod.js';
import { PrintClientInvoicesPage } from '../pages/SearchClientInvoices/PrintClientInvoicesPage.js';
import { Supplier1099ReportPage } from '../pages/Supplier1099ReportPage/Supplier1099ReportPage.js';
import { OpenSupplierInvoicesPage } from '../pages/OpenSupplierInvoices/OpenSupplierInvoicesPage.js';
import {
  BfsActiveBatchInvoicingPage,
  BfsDormantBatchInvoicingPage,
} from '../pages/BatchInvoicingTM/BfsBatchInvoicingPage.js';
import {
  NewYorkSalesTaxReportPage,
  SalesTaxReportByStatePage,
  WisconsinSalesTaxReportPage,
} from '../pages/SalesTaxReport/SalesTaxReportPage.js';
import { ClientSalesReportPage } from '../pages/ClientSalesReportsPage/ClientSalesReportPage.js';
import { ClientInvoicingPage } from '../pages/ClientInvoicing/ClientInvoicingPage.js';
import { RecurringJournalsPage } from '../pages/RecurringJournal/RecurringJournalsPage.js';
import { EditRecurringJournalPage } from '../pages/RecurringJournal/EditRecurringJournalPage.js';
import { VendorLedgerReportPage } from '../pages/VendorLedgerReport/VendorLedgerReportPage.js';
import { BatchGenerationQueuePage } from '../pages/BatchGenerationQueue/BatchGenerationQueuePage.js';
import { BatchInvoiceCompletedPage } from '../pages/BatchInvoiceCompleted/BatchInvoiceCompletedPage.js';
import { RequiredSetup2FAPage } from '../pages/UserSettings/RequiredSetup2FAPage.js';
import { WorkInProgressQCReportPage } from '../pages/WorkInProgressQCReport/WorkInProgressQCReportPage.js';
import { SupplierDocumentExceptionsPage } from '../pages/SupplierDocumentExceptions/SupplierDocumentExceptionsPage.js';
import { ApproveSupplierInvoicesPage } from '../pages/ApproveSupplierInvoices/ApproveSupplierInvoicesPage.js';
import { DailySupplierInvoicesBatchPage } from '../pages/DailySupplierInvoicesBatch/DailySupplierInvoicesBatchPage.js';
import { UmcBatchInvoicingPage } from '../pages/BatchInvoicingTM/UmcBatchInvoicingPage.js';
import { FinancialDirectoryPageLayout } from '../layouts/FinancialDirectoryPageLayout/FinancialDirectoryPageLayout.js';
import { FinancialDirectoryTempPage } from '../pages/FinancialDirectory/FinancialDirectoryPage.js';
import { ReceiveClientPaymentsPage } from '../pages/PayClientPayments/ReceiveClientPaymentsPage.js';
import { MasterProjectCard } from '../pages/MasterProjectCard.js';
import { PropertyCard } from '../pages/PropertyCard.js';
import { ProjectCard } from '../pages/ProjectCard.js';
import { ClientCard } from '../pages/ClientCard.js';
import { AssetManufacturersPage } from '../pages/AssetManufacturers/AssetManufacturersPage.js';
import { AssetTypesPage } from '../pages/AssetTypes/AssetTypesPage.js';
import { RegionalManagementBatchInvoicingPage } from '../pages/BatchInvoicingTM/RegionalManagementBatchInvoicingPage.js';
import { RegionalManagementJanitorialBatchInvoicingPage } from '../pages/BatchInvoicingTM/RegionalManagementJanitorialBatchInvoicingPage.js';
import { GenerateAssetQRCodesPage } from '../pages/GenerateAssetQRCodes/GenerateAssetQRCodesPage.js';
import { PropertyHierarchyManagementPage } from '../pages/PropertyHierarchyManagement/PropertyHierarchyManagementPage.js';
import { ClientGLCodesPage } from '../pages/ClientGLCodesPage/ClientGLCodesPage.js';
import { BatchInvoicingMondelezPage } from '../pages/BatchInvoicingMondelez/BatchInvoicingMondelezPage.js';
import { ClientInvoicingCollectionPage } from '../pages/ClientInvoicingCollection/ClientInvoicingCollectionPage.js';
import { AssetManufactureModelsPage } from '../pages/AssetManufactureModels/AssetManufactureModelsPage.js';
import { RequestedSupplierDocumentsReviewPage } from '../pages/RequestedSupplierDocumentsReview/RequestedSupplierDocumentsReviewPage.js';
import { DocumentsRequestPage } from '../pages/DocumentsRequest/DocumentsRequestPage.js';
import { IncomeStatementReportPage } from '../pages/IncomeStatementReport/IncomeStatementReportPage.js';
import { AssetReviewPage } from '../pages/AssetReview/AssetReviewPage.js';
import { EditClientPaymentsPage } from '../pages/EditClientPayments/EditClientPaymentsPage.js';
import { SupplierAttachInvoiceFilePage } from '../pages/SupplierAttachInvoiceFile/SupplierAttachInvoiceFilePage.js';
import { SubcontractorAgreementPage } from '../pages/SubcontractorAgreementPage/SubcontractorAgreementPage.js';
import { RedirectToSubcontractorAgreementPage } from '../pages/SubcontractorAgreementPage/RedirectToSubcontractorAgreementPage.js';
import { PurchaseOrdersPage } from '../pages/PurchaseOrdersPage/PurchaseOrdersPage.js';
import { MyRecurringProjects } from '../pages/MyRecurringProjects/MyRecurringProjects.js';
import { LockPreviousMonthPage } from '../pages/LockPreviousMonth/LockPreviousMonthPage.js';
import { SearchVoidClientInvoicesPage } from '../pages/SearchVoidClientInvoices/SearchVoidClientInvoicesPage.js';
import { UserGroupManagementPage } from '../pages/UserGroupManagement/UserGroupManagementPage.js';
import { RequesterSurveyPage } from '../pages/RequesterSurvey/RequesterSurveyPage.js';
import { QuoteDiscrepanciesPage } from '../pages/QuoteDiscrepancies/QuoteDiscrepanciesPage.js';
import { OpenWorkOrdersReport } from '../pages/OpenWorkOrdersReport/OpenWorkOrdersReport.js';
import { ManageProceduresPage } from '../pages/ManageProcedures/ManageProceduresPage.js';
import { SuppliersQuoteDiscrepanciesPage } from '../pages/SuppliersQuoteDiscrepancies/SuppliersQuoteDiscrepanciesPage.js';
import { TaskTemplatesPage } from '../pages/TaskTemplates/TaskTemplatesPage.js';
import { WOCAssetsDownloadPage } from '../pages/WOCAssetsDownloadPage.js';
import { CompleteConfirmSatisfactionPage } from '../pages/CompleteConfirmSatisfaction/CompleteConfirmSatisfactionPage.js';
import { ConfirmSatisfactionReportPage } from '../pages/ConfirmSatisfactionReport/ConfirmSatisfactionReportPage.js';
import { SubCategoryReportPage } from '../pages/SubCategoryReport/SubCategoryReportPage.js';
import { ClientSurveysSummaryReportPage } from '../pages/ClientSurveysSummaryReport/ClientSurveysSummaryReportPage.js';
import { DetailedClientSurveysReportPage } from '../pages/DetailedClientSurveysReport/DetailedClientSurveysReportPage.js';
import { ReassignUserPage } from '../pages/ReassignUserPage/ReassignUserPage.js';
import { BulkEditingPage } from '../pages/BulkEditing/BulkEditingPage.js';
import { ProblemCodeLibraryPage } from '../pages/ProblemCodeLibrary/ProblemCodeLibraryPage.js';
import { MondelezProjectRMStatusPage } from '../pages/MondelezProjectRMStatus/MondelezProjectRMStatusPage.js';

export const appRoutesConfig = [
  {
    layout: EmptyLayout,
    routes: [[routesNames.SETUP_2FA, RequiredSetup2FAPage]],
  },

  {
    layout: PublicLayout,
    routes: [
      [routesNames.LOGIN, LoginPage],
      [routesNames.FORGOT_PASSWORD, ForgotPasswordPage],
      [routesNames.RESET_PASSWORD, ResetPasswordPage],
      [routesNames.SUPPLIERS_MAP, SuppliersMapPage],
      [
        routesNames.SUPPLIER_ATTACH_SERVICE_TICKET_FILE,
        SupplierAttachServiceTicketFilePage,
      ],
      [routesNames.SUPPLIER_ATTACH_INVOICE_FILE, SupplierAttachInvoiceFilePage],
      [
        routesNames.COMPLETE_CONFIRM_SATISFACTION,
        CompleteConfirmSatisfactionPage,
      ],
      [routesNames.DOCUMENTS_REQUEST, DocumentsRequestPage],
      [routesNames.VENDOR_INFO, VendorInfoPage],
      [routesNames.SUBCONTRACTOR_AGREEMENT, SubcontractorAgreementPage],
      // temporary route to redirect to new agreement page due to https://gitlab.com/askpoly/poly-apps/-/issues/4829
      [
        '/public/suppliers/terms-and-conditions',
        RedirectToSubcontractorAgreementPage,
      ],
      [routesNames.REQUESTOR_SUBMIT_SURVEY, RequesterSurveyPage],
      [routesNames.WOC_ASSETS_DOWNLOAD, WOCAssetsDownloadPage],
    ],
  },
  {
    layout: FinancialDirectoryPageLayout,
    routes: [
      [routesNames.FINANCIAL_DIRECTORY, FinancialDirectoryTempPage],
      [routesNames.BANK_RECONCILIATION, BankReconciliationPage],
      [routesNames.PAY_CLIENT_INVOICES, ReceiveClientPaymentsPage],
    ],
  },
  {
    layout: MainLayout,
    routes: [
      [routesNames.ADMIN_SETTINGS, AdminSettingsPage],
      [routesNames.GENERATE_ASSET_QR_CODES, GenerateAssetQRCodesPage],
      [routesNames.REPORTS, ReportsPage],
      [routesNames.MY_TASKS, MyTasksPage],
      [routesNames.ADD_SUPPLIER, AddSupplierPage],
      [routesNames.MY_PROJECTS, MyProjectsPage],
      [routesNames.SUPPLIER, SupplierPage],
      [routesNames.SUPPLIERS_ADMINISTRATIVE_AAC, SuppliersAdminAACPage],
      [routesNames.SUPPLIERS_SUBCONTRACTORS, SuppliersSubcontractorPage],
      [routesNames.ACCOUNT_INDEX, AccountIndexPage],
      [routesNames.PROSPECTS, ProspectsPage],
      [routesNames.CLIENTS, ClientsPage],
      [routesNames.PROJECTS_DIRECTORY, ProjectsPage],
      [routesNames.UNASSIGNED_PROJECTS, UnassignedProjectsPage],
      [routesNames.STAFF_DIRECTORY, StaffDirectoryPage],
      [routesNames.MENTION_GROUPS, MentionGroupsPage],
      [routesNames.HOME, HomePage],
      [routesNames.RECENT_PROJECTS_REPORT, RecentProjectReportPage],
      [routesNames.SUPPLIER_SEARCH, SupplierSearchPage],
      [routesNames.STAFF_ACTIVITY_REPORT, StaffReportPage],
      [routesNames.STAFF_ACTIVITY_REPORT_DETAILS, StaffReportDetailsPage],
      [routesNames.TIME_SHEET, TimesheetPage],
      [routesNames.TIME_SHEET_REPORT, TimeSheetReportPage],
      [routesNames.TIME_SHEET_REPORTS, TimeSheetReportsPage],
      [routesNames.SERVICE_TYPES, ServiceTypesManagementPage],
      [routesNames.ASSET_MANUFACTURERS, AssetManufacturersPage],
      [routesNames.ASSET_TYPES, AssetTypesPage],
      [routesNames.MASTER_PROJECTS_DIRECTORY, MasterProjectsPage],
      [routesNames.MY_MASTER_PROJECTS_DIRECTORY, MyMasterProjectsPage],
      [routesNames.EMERGENCY_PROJECTS, EmergencyProjectsPage],
      [routesNames.MY_EMERGENCY_PROJECTS, MyEmergencyProjectsPage],
      [routesNames.RECALLED_PROJECTS, RecalledProjectsPage],
      [routesNames.SPEND_REPORTS, SpendReportsPage],
      [routesNames.QUOTE_DISCREPANCIES, QuoteDiscrepanciesPage],
      [routesNames.ENTER_SUPPLIER_INVOICE, EnterSupplierInvoicePage],
      [routesNames.PAY_SUPPLIERS, PaySuppliersPage],
      [routesNames.PRINT_CHECKS, PrintChecksPage],
      [routesNames.PRINT_CHECKS_DETAILS, PrintChecksDetailsPage],
      [routesNames.SETTINGS, UserSettingsPage],
      [routesNames.SUPPLIER_PAYMENT_HISTORY, SupplierPaymentHistoryPage],
      [routesNames.SEARCH_SUPPLIER_INVOICES, SearchSupplierInvoicesPage],
      [routesNames.EDIT_SUPPLIER_INVOICE, EditSupplierInvoicePage],
      [routesNames.ACCOUNT_TYPES, AccountTypesPage],
      [routesNames.DIVISIONS, DivisionsPage],
      [routesNames.CHART_OF_ACCOUNTS, ChartOfAccountsPage],
      [routesNames.VOID_SUPPLIER_PAYMENTS, VoidSupplierPaymentsPage],
      [routesNames.GENERAL_LEDGER, GeneralLedgerPage],
      [routesNames.ACCOUNT_TRANSACTIONS, AccountTransactionsPage],
      [routesNames.CREDIT_CARD_REGISTERS, CreditCardRegistersPage],
      [routesNames.BANK_REGISTERS, BankRegistersPage],
      [routesNames.BANK_RECONCILIATION, BankReconciliationPage],
      [routesNames.BANK_RECONCILIATION_REPORT, BankReconciliationReportPage],
      [routesNames.BANK_DEPOSITS, BankDepositsPage],
      [
        routesNames.HOUSEKEEPING_BATCH_INVOICING,
        HousekeepingBatchInvoicingPage,
      ],
      [routesNames.BASS_PRO_TM_BATCH_INVOICING, BatchInvoicingTMBassProPage],
      [routesNames.BASS_PRO_BID_BATCH_INVOICING, BatchInvoicingBidBassProPage],
      [routesNames.BFS_DORMANT_BATCH_INVOICING, BfsDormantBatchInvoicingPage],
      [routesNames.BFS_ACTIVE_BATCH_INVOICING, BfsActiveBatchInvoicingPage],
      [routesNames.UMC_BATCH_INVOICING, UmcBatchInvoicingPage],
      [
        routesNames.REGIONAL_MANAGEMENT_JANITORIAL_BATCH_INVOICING,
        RegionalManagementJanitorialBatchInvoicingPage,
      ],
      [
        routesNames.REGIONAL_MANAGEMENT_BATCH_INVOICING,
        RegionalManagementBatchInvoicingPage,
      ],
      [routesNames.CREATE_JOURNAL_ENTRY, CreateJournalEntryPage],
      [routesNames.JOURNAL_ENTRY_LEDGER, JournalEntryLedgerPage],
      [routesNames.ACCOUNTS_PAYABLE_REPORT, AccountsPayableReportPage],
      [routesNames.ACCOUNTS_RECEIVABLE_REPORT, AccountsReceivableReportPage],
      [routesNames.AP_AGING_REPORT, APAgingReportPage],
      [routesNames.AR_AGING_REPORT, ARAgingReportPage],
      [routesNames.CLIENT_INVOICE_HISTORY, ClientInvoiceHistoryPage],
      [
        routesNames.HOUSEKEEPING_SERVICE_TICKETS_REPORT,
        HouseKeepingServiceTicketsReportPage,
      ],
      [routesNames.CLIENT_HISTORY, ClientHistoryPage],
      [routesNames.EDIT_CLIENT_PAYMENTS, EditClientPaymentsPage],
      [routesNames.BATCH_INVOICE_REPORTS, BatchInvoiceReportsPage],
      [
        routesNames.BATCH_INVOICE_REPORTS_DETAILS,
        BatchInvoiceReportsDetailsPage,
      ],
      [routesNames.WORK_IN_PROGRESS_REPORT, WorkInProgressReportPage],
      [routesNames.CREDIT_CARD_RECONCILIATION, CreditCardReconciliationPage],
      [
        routesNames.CREDIT_CARD_RECONCILIATION_REPORT,
        CreditCardReconciliationReportPage,
      ],
      [routesNames.SUPPLIER_AP_REPORT, SupplierApReportPage],
      [routesNames.BALANCE_SHEET, BalanceSheetPage],
      [routesNames.INCOME_STATEMENT_REPORT, IncomeStatementReportPage],
      [routesNames.PROFIT_LOSS_REPORT, ProfitLossReportPage],
      [routesNames.SEARCH_TRANSACTIONS, SearchTransactionsPage],
      [routesNames.TRANSFER_FUNDS, TransferFundsPage],
      [routesNames.CREDIT_CARD_USERS, CreditCardUsersPage],
      [routesNames.PAY_BY_BANK, PayByBankPage],
      [routesNames.PAY_BY_CREDIT_CARD, PayByCreditCardPage],
      [routesNames.TRIAL_BALANCE, TrialBalancePage],
      [routesNames.INPUT_PAYROLL, InputPayrollPage],
      [routesNames.SEARCH_CLIENT_INVOICES, SearchClientInvoicesPage],
      [routesNames.SEARCH_VOID_CLIENT_INVOICES, SearchVoidClientInvoicesPage],
      [routesNames.CREATE_CLIENT_INVOICE_SEARCH, CreateClientInvoiceSearchPage],
      [routesNames.CREATE_CLIENT_INVOICE, CreateClientInvoicePage],
      [routesNames.EDIT_CLIENT_INVOICE, EditClientInvoicePage],
      [
        routesNames.MASTER_SUPPLIERS_ADMINISTRATIVE,
        MasterSuppliersAdministrativePage,
      ],
      [
        routesNames.MASTER_SUPPLIERS_SUBCONTRACTORS,
        MasterSuppliersSubcontractorsPage,
      ],
      [routesNames.ADD_MASTER_SUPPLIER, AddMasterSupplierPage],
      [routesNames.MASTER_SUPPLIER, MasterSupplierPage],
      [routesNames.ADD_BRANCH_SUPPLIER, AddBranchSupplierPage],
      [routesNames.MASTER_SUPPLIER_AP_REPORT, MasterSupplierAPReportPage],
      [routesNames.CLOSE_FINANCIAL_YEAR, CloseFinancialYearPage],
      [routesNames.POST_CLOSING_JOURNAL, ClosingFinancialYearJournalPage],
      [routesNames.REOPEN_FINANCIAL_YEAR, ReopenAccountingPeriod],
      [routesNames.PRINT_CLIENT_INVOICE, PrintClientInvoicesPage],
      [routesNames.SUPPLIER_1099_REPORT, Supplier1099ReportPage],
      [routesNames.OPEN_SUPPLIER_INVOICE, OpenSupplierInvoicesPage],
      [routesNames.WISCONSIN_TAX_REPORT, WisconsinSalesTaxReportPage],
      [routesNames.NEW_YORK_TAX_REPORT, NewYorkSalesTaxReportPage],
      [routesNames.SALES_TAX_REPORT_BY_STATE, SalesTaxReportByStatePage],
      [routesNames.CLIENT_SALES_REPORT, ClientSalesReportPage],
      [routesNames.CLIENT_INVOICING, ClientInvoicingPage],
      [routesNames.RECURRING_JOURNALS, RecurringJournalsPage],
      [routesNames.EDIT_RECURRING_JOURNAL, EditRecurringJournalPage],
      [routesNames.VENDOR_LEDGER_REPORT, VendorLedgerReportPage],
      [routesNames.BATCH_GENERATION_QUEUE, BatchGenerationQueuePage],
      [routesNames.COMPLETED_BATCHES, BatchInvoiceCompletedPage],
      [routesNames.WORK_IN_PROGRESS_QC_REPORT, WorkInProgressQCReportPage],
      [
        routesNames.SUPPLIER_DOCUMENT_EXCEPTIONS,
        SupplierDocumentExceptionsPage,
      ],
      [routesNames.APPROVE_SUPPLIER_INVOICES, ApproveSupplierInvoicesPage],
      [
        routesNames.DAILY_SUPPLIER_INVOICE_BATCH,
        DailySupplierInvoicesBatchPage,
      ],
      [routesNames.MASTER_PROJECT_CARD, MasterProjectCard],
      [routesNames.PROPERTY_CARD, PropertyCard],
      [routesNames.PROJECT_CARD, ProjectCard],
      [routesNames.CLIENT_CARD, ClientCard],
      [
        routesNames.PROPERTY_HIERARCHY_MANAGEMENT,
        PropertyHierarchyManagementPage,
      ],
      [routesNames.CLIENT_GL_CODES, ClientGLCodesPage],
      [routesNames.MONDELEZ_INVOICING, BatchInvoicingMondelezPage],
      [routesNames.CLIENT_INVOICING_COLLECTION, ClientInvoicingCollectionPage],
      [routesNames.ASSET_MANUFACTURER_MODELS, AssetManufactureModelsPage],
      [routesNames.ASSET_REVIEW, AssetReviewPage],
      [routesNames.BULK_EDITING, BulkEditingPage],
      [
        routesNames.NEW_SUPPLIER_DOCUMENTS_REVIEW,
        RequestedSupplierDocumentsReviewPage,
      ],
      [routesNames.PURCHASE_ORDERS, PurchaseOrdersPage],
      [routesNames.MY_RECURRING_PROJECTS_DIRECTORY, MyRecurringProjects],
      [routesNames.LOCK_PREVIOUS_MONTH, LockPreviousMonthPage],
      [routesNames.USER_GROUP_MANAGEMENT, UserGroupManagementPage],
      [routesNames.OPEN_WORK_ORDERS_REPORT, OpenWorkOrdersReport],
      [routesNames.MANAGE_PROCEDURES, ManageProceduresPage],
      [
        routesNames.SUPPLIERS_QUOTE_DISCREPANCIES,
        SuppliersQuoteDiscrepanciesPage,
      ],
      [routesNames.TASK_TEMPLATES, TaskTemplatesPage],
      [routesNames.CONFIRM_SATISFACTION_REPORT, ConfirmSatisfactionReportPage],
      [routesNames.SUB_CATEGORY_REPORT, SubCategoryReportPage],
      [
        routesNames.CLIENT_SURVEYS_SUMMARY_REPORT,
        ClientSurveysSummaryReportPage,
      ],
      [
        routesNames.DETAILED_CLIENT_SURVEYS_REPORT,
        DetailedClientSurveysReportPage,
      ],
      [routesNames.REASSIGN_USER, ReassignUserPage],
      [routesNames.PROBLEM_CODE_LIBRARY, ProblemCodeLibraryPage],
      [
        routesNames.MONDELEZ_RM_PROJECT_STATUS_REPORT,
        MondelezProjectRMStatusPage,
      ],
      [routesNames.NOT_FOUND, NotFoundPage],
    ],
  },
];
