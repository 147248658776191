import * as R from 'ramda';
import React from 'react';
import { ProjectType } from '@poly/constants';
import { MoneyInput, formTypeConstants } from '@poly/admin-ui';
import { Input, NumberInputHTML5 } from '@poly/admin-book';
import {
  formatTotal,
  isNilOrEmpty,
  validationFuncWithAllValues,
  propEqLegacy,
} from '@poly/utils';

import { CheckboxWithLabel } from '../components/CheckboxWithLabel.js';
import { DivisionSelect } from '../../../../../components/DivisionSelect.js';
import { halfWidth, thirdWidth } from '../../../../../modules/forms/common.js';
import { NonBillableReasonSelect } from '../components/NonBillableReasonSelect.js';
import {
  isNotRecurringAndFeeProject,
  isRecurringOrPMFormData,
  isRecurringFormData,
  isNotFeeProjectType,
  isFeeProjectType,
  isProjectOfType,
  isPMFormData,
  isFormType,
  isNotPaidOrInvoicedAccountingStatus,
} from '../utils/sections.js';
import { AdminPOSelector } from '../components/AdminPOSelector.js';
import {
  OUTDATE_ADMIN_PO_WARNING_MSG,
  validateAminPurchaseOrder,
} from './validateAminPurchaseOrder.js';

const MAXIMAL_FEE_AMOUNT = 999999;

// isHousekeepingProjectType :: ProjectFormData -> Boolean
const isHousekeepingProjectType = propEqLegacy(
  'type',
  ProjectType.HOUSEKEEPING,
);

// isNotFeeAndHousekeepingProjectType :: ProjectFormData -> Boolean
const isNotFeeAndHousekeepingProjectType = R.both(
  isNotFeeProjectType,
  R.complement(isHousekeepingProjectType),
);

// renderIfRecurringHousekeeping :: ProjectFormData -> Boolean
const renderIfRecurringHousekeeping = R.both(
  isRecurringFormData,
  isHousekeepingProjectType,
);

// isAllowedToNonBillable :: ProjectFormData -> Boolean
const isAllowedToNonBillable = R.either(
  R.allPass([
    isNotRecurringAndFeeProject,
    isFormType(formTypeConstants.EDIT),
    isNotPaidOrInvoicedAccountingStatus,
  ]),
  R.both(R.prop('isCallback'), isNotPaidOrInvoicedAccountingStatus),
);

export const exemptSalesTaxFormFieldConfig = {
  order: 1,
  layout: { row: 1, width: thirdWidth },
  field: {
    name: 'exemptSalesTax',
    Component: (props) => (
      <CheckboxWithLabel {...props} label="Exempt from Sales Tax" />
    ),
  },
  renderIf: isNotRecurringAndFeeProject,
};

export const afterHoursCallFormFieldConfig = {
  order: 2,
  layout: { row: 1, width: thirdWidth },
  field: {
    name: 'isAfterHoursCall',
    Component: (props) => (
      <CheckboxWithLabel {...props} label="After Hours Call" />
    ),
  },
  renderIf: R.allPass([
    isNotRecurringAndFeeProject,
    R.pathSatisfies(R.isNil, ['parent', '_id']),
  ]),
};

export const divisionSelectConfig = {
  order: 3,
  label: 'Division',
  layout: { row: 1, width: halfWidth },
  field: {
    name: 'divisionAccountId',
    withFormData: true,
    Component: DivisionSelect,
  },
};

export const clientNotToExceedFinancialFormFieldConfig = {
  order: 4,
  label: 'Client Not to Exceed',
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'nte',
    Component: MoneyInput,
  },
  renderIf: R.allPass([
    isNotFeeAndHousekeepingProjectType,
    R.either(isRecurringFormData, isPMFormData),
    R.complement(isProjectOfType(ProjectType.BID)),
  ]),
  leaveValues: true,
};

export const bidAmountFinancialFormFieldConfig = {
  label: 'Bid Amount',
  layout: { row: 2, width: halfWidth },
  order: 4,
  field: {
    name: 'bidAmount',
    additionalProps: { allowNegative: true },
    Component: MoneyInput,
  },
  leaveValues: true,
  renderIf: R.both(
    R.either(isRecurringFormData, isPMFormData),
    isProjectOfType(ProjectType.BID),
  ),
};

export const feeAmountFormFieldConfig = {
  order: 6,
  label: 'Fee Amount',
  layout: { row: 8, width: thirdWidth },
  field: {
    name: 'feeAmount',
    Component: MoneyInput,
  },
  required: true,
  validators: [
    [
      R.gte(MAXIMAL_FEE_AMOUNT),
      `Amount should be less then or equal to ${formatTotal(
        MAXIMAL_FEE_AMOUNT,
      )}`,
    ],
  ],
  renderIf: isFeeProjectType,
  leaveValues: true,
};

export const monthlyHousekeepingClientFormFieldConfig = {
  order: 7,
  label: 'Monthly Housekeeping Client',
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'clientMonthlyFee',
    Component: MoneyInput,
  },
  required: true,
  validators: [[R.identity, 'Monthly Housekeeping Client is required']],
  renderIf: renderIfRecurringHousekeeping,
  leaveValues: true,
};

export const monthlyHousekeepingSupplierFormFieldConfig = {
  order: 8,
  label: 'Monthly Housekeeping Supplier',
  layout: { row: 2, width: halfWidth },
  field: {
    name: 'supplierMonthlyFee',
    Component: MoneyInput,
  },
  required: true,
  validators: [[R.identity, 'Monthly Housekeeping Supplier is required']],
  renderIf: renderIfRecurringHousekeeping,
  leaveValues: true,
};

export const clientHourlyRateFormFieldConfig = {
  order: 9,
  label: 'Client Hourly Rate',
  layout: { row: 3, width: halfWidth },
  field: {
    name: 'clientHourlyRate',
    Component: MoneyInput,
  },
  required: true,
  validators: [[R.is(Number), 'Client Hourly Rate is required']],
  renderIf: renderIfRecurringHousekeeping,
  leaveValues: true,
};

export const supplierHourlyRateFormFieldConfig = {
  order: 10,
  label: 'Supplier Hourly Rate',
  layout: { row: 3, width: halfWidth },
  field: {
    name: 'supplierHourlyRate',
    Component: MoneyInput,
  },
  required: true,
  validators: [[R.is(Number), 'Supplier Hourly Rate is required']],
  renderIf: renderIfRecurringHousekeeping,
  leaveValues: true,
};

export const weeklyPorterHoursFormFieldConfig = {
  order: 11,
  label: 'Weekly Porter Hours',
  layout: { row: 8, width: halfWidth },
  field: {
    name: 'weeklyPorterHours',
    Component: NumberInputHTML5,
  },
  required: true,
  validators: [
    [R.is(Number), 'Weekly Porter Hours is required'],
    [(num) => num >= 0, 'Must be 0 or greater'],
  ],
  renderIf: renderIfRecurringHousekeeping,
  leaveValues: true,
};

// isRenderClientPO :: Project -> Boolean
const isRenderClientPO = R.both(
  R.complement(isHousekeepingProjectType),
  R.either(isRecurringFormData, isPMFormData),
);

export const clientRefNoPoFinancialFormFieldConfig = {
  order: 12,
  label: 'Client Ref # / PO',
  layout: { row: 8, width: halfWidth },
  field: {
    name: 'clientReferenceNumber',
    Component: Input,
  },
  renderIf: R.both(
    isRenderClientPO,
    R.complement(R.path(['client', 'configs', 'enablePurchaseOrder'])),
  ),
  leaveValues: true,
};

export const clientPOFinancialFormFieldConfig = {
  order: 12,
  label: 'Client Ref # / PO',
  layout: { row: 8, width: halfWidth },
  field: {
    name: 'adminPurchaseOrderId',
    withFormData: true,
    withChangeFieldValue: true,
    Component: AdminPOSelector,
  },
  renderIf: R.both(
    isRenderClientPO,
    R.path(['client', 'configs', 'enablePurchaseOrder']),
  ),
  validators: [[validateAminPurchaseOrder, OUTDATE_ADMIN_PO_WARNING_MSG]],
  validateFunction: validationFuncWithAllValues,
  leaveValues: true,
};

export const nonBillableFormFieldConfig = {
  order: 14,
  layout: { row: 10, width: '29%', justify: 'flex-start' },
  field: {
    name: 'nonBillable',
    Component: (props) => <CheckboxWithLabel {...props} label="Non Billable" />,
  },
  renderIf: isAllowedToNonBillable,
};

export const nonBillableReasonFormConfigs = [
  {
    order: 15,
    label: 'Non Billable Reason',
    layout: { row: 10, width: '28%', margin: '0 10px 0 0' },
    field: {
      name: 'nonBillableReason',
      Component: NonBillableReasonSelect,
    },
    required: true,
    validators: [[R.identity, 'Non Billable Reason is required']],
    renderIf: R.prop('nonBillable'),
  },
  {
    order: 15,
    layout: { row: 10, width: '28%', margin: '0 10px 0 0' },
    field: {
      name: 'nonBillablePlaceholder',
      Component: () => null,
    },
    renderIf: R.both(
      isAllowedToNonBillable,
      R.complement(R.prop('nonBillable')),
    ),
  },
];

export const costCenterFormFieldConfig = {
  order: 16,
  label: 'Cost Center',
  layout: { row: 10, width: 'calc(45% - 10px)' },
  field: {
    name: 'costCenter',
    withFormData: true,
    Component: Input,
  },
  renderIf: R.allPass([
    R.complement(isRecurringOrPMFormData),
    R.path(['client', 'enableCostCenter']),
    R.compose(isNilOrEmpty, R.prop('subPropertiesIds')),
  ]),
};
