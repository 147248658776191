import * as R from 'ramda';
import React from 'react';
import { shape, string } from 'prop-types';
import { WorkOrderStatus } from '@poly/constants';
import { DatePicker } from '@poly/admin-book';
import { isChildRecurringProject } from '@poly/client-utils';
import { isHoursProjectPriorityUnit, propEqLegacy } from '@poly/utils';

import { isFeeProjectType } from '../utils/sections.js';
import { formTypeConstants } from '../utils/constants.js';
import { WorkPrioritySelectField } from '../components/WorkPrioritySelectField.js';

export function StartDatePicker({
  formData: { priorityId, formType, endDate, client },
  ...restProps
}) {
  const disabled = formType === formTypeConstants.EDIT;

  const isHoursUnit = isHoursProjectPriorityUnit({ priorityId, client });

  return (
    <DatePicker
      {...restProps}
      width="100%"
      disabled={disabled || isHoursUnit}
      disabledDays={{ after: endDate }}
    />
  );
}

StartDatePicker.propTypes = {
  formData: shape({
    formType: string,
    priorityId: string,
  }),
};

function EndDatePicker({
  formData: { priorityId, client, status, startDate },
  ...restProps
}) {
  const isHoursUnit = isHoursProjectPriorityUnit({ priorityId, client });

  const disabled = status === WorkOrderStatus.COMPLETED || isHoursUnit;

  return (
    <DatePicker
      {...restProps}
      width="100%"
      disabled={disabled}
      leftMove="-120px"
      disabledDays={{ before: startDate }}
    />
  );
}

EndDatePicker.propTypes = {
  formData: shape({
    status: string,
  }),
};

function WorkCompletionDateDatePicker(props) {
  return <DatePicker {...props} width="100%" />;
}

const dateValidators = [[R.identity, 'Date is required']];

// endDateField :: (ProjectFormObject -> Boolean, String) -> Object
const endDateField = (renderIf, width) => ({
  label: 'End Date',
  layout: { row: 1, width },
  order: 2,
  field: {
    name: 'endDate',
    withFormData: true,
    Component: EndDatePicker,
  },
  leaveValues: true,
  renderIf,
  validators: dateValidators,
});

// startDateField :: (ProjectFormObject -> Boolean, String) -> Object
const startDateField = (renderIf, width) => ({
  label: 'Start Date',
  layout: { row: 1, width },
  order: 2,
  field: {
    name: 'startDate',
    withFormData: true,
    Component: StartDatePicker,
  },
  leaveValues: true,
  renderIf,
  validators: dateValidators,
});

// isChildRecurringProjectOrFeeType :: ProjectFormData -> Boolean
const isChildRecurringProjectOrFeeType = R.either(
  isChildRecurringProject,
  isFeeProjectType,
);

export const workPriorityFormFieldConfig = {
  label: 'Work Priority',
  field: {
    name: 'priorityId',
    withFormData: true,
    withChangeFieldValue: true,
    Component: WorkPrioritySelectField,
  },
  renderIf: R.complement(isChildRecurringProjectOrFeeType),
  required: true,
};

export const startDateThirdFormFieldConfig = startDateField(
  R.complement(isChildRecurringProjectOrFeeType),
  'calc(32% - 10px)',
);

export const endDateThirdFormFieldConfig = endDateField(
  R.complement(isChildRecurringProjectOrFeeType),
  'calc(32% - 10px)',
);

export const startDateHalfFormFieldConfig = startDateField(
  isChildRecurringProjectOrFeeType,
  'calc(50% - 10px)',
);

export const endDateHalfFormFieldConfig = endDateField(
  isChildRecurringProjectOrFeeType,
  'calc(50% - 10px)',
);

const workCompletionDateField = (renderIf, width) => ({
  label: 'Work Completion Date',
  order: 1,
  layout: { row: 3, width },
  leaveValues: true,
  field: {
    name: 'workCompletionDate',
    Component: WorkCompletionDateDatePicker,
  },
  renderIf: R.allPass([
    renderIf,
    propEqLegacy('status', WorkOrderStatus.COMPLETED),
    R.propSatisfies(R.complement(R.isNil), 'workCompletionDate'),
    R.propSatisfies(R.isNil, 'cloneFromProjectId'),
  ]),
});

export const workCompletionDateThirdFormField = workCompletionDateField(
  R.complement(isChildRecurringProjectOrFeeType),
  'calc(32% - 10px)',
);

export const workCompletionDateHalfFormField = workCompletionDateField(
  isChildRecurringProjectOrFeeType,
  'calc(50% - 10px)',
);
