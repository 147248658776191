import * as R from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { sqlSortQuery } from '@poly/client-utils';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  formatCurrency,
  calculateTotal,
  formatDate,
  applyProp,
} from '@poly/utils';

import {
  CustomerHistoryTableS,
  CustomerHistoryFooterContainer,
} from './components.js';
import { customerTransactionPropType } from './propTypes.js';
import {
  getTransactionUserFriendlyName,
  getTransactionUserFriendlyType,
} from '../VendorLedgerReport/vendorLedgerReportUtils.js';

// getAmountByProp :: String -> SupplierPaymentTransaction -> String
const getAmountByProp = (prop) => R.compose(formatCurrency, R.propOr(0, prop));

export const customerTransactionHistoryConfig = [
  [
    'Transaction',
    getTransactionUserFriendlyName,
    sqlSortQuery('transaction_sort'),
  ],
  ['Date', applyProp(formatDate)('date'), sqlSortQuery('date', 'date')],
  ['Type', getTransactionUserFriendlyType, sqlSortQuery('type_sort')],
  [
    'Project',
    R.path(['project', 'projectId']),
    sqlSortQuery('project_id_sort'),
  ],
  [
    'Invoice Amount',
    getAmountByProp('invoiceAmount'),
    sqlSortQuery('invoice_amount', 'numeric'),
  ],
  [
    'Paid Amount',
    getAmountByProp('paymentAmount'),
    sqlSortQuery('payment_amount', 'numeric'),
  ],
  ['Credit Memo', getAmountByProp('creditMemo')],
];

// getTotalByProp :: (String, [Transaction]) -> String
const getTotalByProp = (prop, rows) =>
  R.compose(formatCurrency, calculateTotal(R.prop(prop)))(rows);

function TotalFooter({ rows }) {
  return (
    <CustomerHistoryFooterContainer>
      <td>Total</td>
      <td>{getTotalByProp('invoiceAmount', rows)}</td>
      <td>{getTotalByProp('paymentAmount', rows)}</td>
      <td>{getTotalByProp('creditMemo', rows)}</td>
    </CustomerHistoryFooterContainer>
  );
}

TotalFooter.propTypes = {
  rows: arrayOf(customerTransactionPropType),
};

export function CustomerHistoryTable({ transactions, ...props }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    customerTransactionHistoryConfig,
    transactions,
  );

  return (
    <CustomerHistoryTableS
      {...tableProps}
      {...props}
      TableFooter={TotalFooter}
      footerHeight={60}
    />
  );
}

CustomerHistoryTable.propTypes = {
  transactions: arrayOf(customerTransactionPropType),
};
