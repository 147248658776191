import React from 'react';
import { shape } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';

import { getAssetManufacturersExcelConfig } from './getAssetManufacturersExcelConfig.js';
import { PrintExportButtons } from '../../components/ExportButtons.js';
import { useAssetManufacturersLazyQuery } from './useAssetManufacturersLazyQuery.js';
import { AssetManufacturersPrintTable } from './AssetManufacturersPageTable.js';

export function AssetManufacturersExportButtons({ sort }) {
  const { runAssetManufacturersQuery, loading } =
    useAssetManufacturersLazyQuery(sort);

  const handlePrintPDF = async () => {
    const assetManufacturers = await runAssetManufacturersQuery();

    await openPrintWindowWithData({
      fileName: 'asset_manufacturers_export.pdf',
      Layout: CommonPrintLayout,
      Table: AssetManufacturersPrintTable,
      metaData: {
        title: 'Asset Manufacturers',
      },
      assetManufacturers,
    });
  };

  const handleExportXLS = async () => {
    const assetManufacturers = await runAssetManufacturersQuery();

    const config = getAssetManufacturersExcelConfig(assetManufacturers);
    return performExcelExport(config);
  };

  return (
    <PrintExportButtons
      handlePrintPDF={handlePrintPDF}
      handleExportXLS={handleExportXLS}
      loading={loading}
    />
  );
}

AssetManufacturersExportButtons.propTypes = {
  sort: shape({}),
};
