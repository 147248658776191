import React, { useState } from 'react';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';
import { useTableSorting } from '@poly/admin-ui/src/hooks/useTableSorting.js';

import { useAssetManufacturersQuery } from './useAssetManufacturersQuery.js';
import {
  AssetManufacturersPageTable,
  assetManufacturersTableConfig,
} from './AssetManufacturersPageTable.js';
import { AssetManufacturersPageHeader } from './AssetManufacturersPageHeader.js';

export function AssetManufacturersPage() {
  const [selectedRows, setSelectedRows] = useState([]);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: assetManufacturersTableConfig,
    column: 1,
  });

  const { assetManufacturers, loading } = useAssetManufacturersQuery(sort);

  return (
    <>
      <AssetManufacturersPageHeader
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        assetManufacturers={assetManufacturers}
        sort={sort}
      />
      <DefaultBodyWrapper height="calc(100% - 100px)">
        <TableCardWithPaginationContainer>
          <AssetManufacturersPageTable
            loading={loading}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            assetManufacturers={assetManufacturers}
            {...tableSortingProps}
          />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </>
  );
}
