import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';

import { ASSET_MANUFACTURERS_QUERY } from './useAssetManufacturersQuery.js';

export const useAssetManufacturersLazyQuery = (sort) => {
  const searchTerm = useSelector(R.prop('searchText'));

  const [loading, setLoading] = useState(false);

  const input = useMemo(
    () => ({
      searchTerm,
      size: MAX_ITEMS,
      sort,
    }),
    [searchTerm, sort],
  );

  const [runQuery] = useLazyQuery(ASSET_MANUFACTURERS_QUERY);

  const runAssetManufacturersQuery = async () => {
    setLoading(true);
    const { data } = await runQuery({
      variables: { input },
    });
    setLoading(false);
    return R.pathOr([], ['searchAssetManufactures', 'hits'], data);
  };

  return {
    loading,
    runAssetManufacturersQuery,
  };
};
