import * as R from 'ramda';
import React, { useState } from 'react';
import { bool, shape, string } from 'prop-types';
import {
  applyPathOrNothingUI,
  isChildRecurringProject,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  LISTING_PROJECT_SPECIFIC_FIELDS_UI_MAP as LISTING_LABELS,
  NOTHING_UI_STRING,
  ProjectType,
} from '@poly/constants';
import { SidebarRow } from '@poly/admin-ui';
import {
  getHousekeepingSupplierExtraHoursTotal,
  getHousekeepingProjectExtraTotalHours,
  getHousekeepingProjectBaseTotalHours,
  getHousekeepingClientExtraHoursCost,
  getHousekeepingClientBaseHoursCost,
  getHousekeepingSupplierHoursCost,
  calculateTotal,
  formatTotal,
  formatDate,
  getHousekeepingWeekCountByProject,
  isRMProject,
  pathEqLegacy,
  isNilOrEmpty,
} from '@poly/utils';

import {
  FeeType,
  ServiceType,
  ProjectAmount,
  ProjectDetailsRow,
  MonthlyHousekeepingRow,
  NotRMComponent,
  SubPropertiesLinks,
  ProjectSpendType,
} from '../components/commonSidebarDetailsList.js';
import {
  ExtraPorterRow,
  CloneProjectRow,
  PorterHourlyRateRow,
  ChildRecurringProjectRow,
  RecallReferenceProject,
  ProjectCallback,
  ProjectCallbacksLinks,
} from './components/projectSidebarDetailsRowList.js';
import { CreditPorterHoursForm } from './forms/CreditPorterHoursForm.js';
import {
  BlockWithLabel,
  SectionWrapper,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import { formatHousekeepingWeeklyPorter } from './projectSidebarUtils.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';
import { ClientReferenceComp } from '../components/ClientReferenceComp.js';

// getHousekeepingProjectDetails :: Project -> Object
const getHousekeepingProjectDetails = R.applySpec({
  propertyFields: R.pathOr({}, ['client', 'configs', 'propertyFields']),
  clientMonthlyFee: R.pipe(R.prop('clientMonthlyFee'), formatTotal),
  clientHourlyRate: formatHousekeepingWeeklyPorter(
    'clientHourlyRate',
    getHousekeepingClientBaseHoursCost,
    getHousekeepingProjectBaseTotalHours,
    false,
  ),
  supplierMonthlyFee: R.pipe(R.prop('supplierMonthlyFee'), formatTotal),
  supplierHourlyRate: formatHousekeepingWeeklyPorter(
    'supplierHourlyRate',
    getHousekeepingSupplierHoursCost,
    getHousekeepingProjectBaseTotalHours,
    false,
  ),
  extraHoursClientRate: formatHousekeepingWeeklyPorter(
    'clientHourlyRate',
    getHousekeepingClientExtraHoursCost,
    getHousekeepingProjectExtraTotalHours,
    true,
  ),
  extraHoursSupplierRate: formatHousekeepingWeeklyPorter(
    'supplierHourlyRate',
    getHousekeepingSupplierExtraHoursTotal,
    getHousekeepingProjectExtraTotalHours,
    true,
  ),
});

// getTicketsTotalHours :: Project -> Number
const getTicketsTotalHours = R.compose(
  calculateTotal(R.prop('hours')),
  R.propOr([], 'weeklyServiceTickets'),
);

// getCreditedPorterHours :: Project -> Number
const getCreditedPorterHours = R.propOr(0, 'creditedPorterHours');

// isSubCategoryEnabled :: { client: Client } -> Boolean
export const isSubCategoryEnabled = pathEqLegacy(
  ['client', 'configs', 'enableProjectSubCategory'],
  true,
);

export function ProjectSubCategoryInfoRow({ project }) {
  if (!isSubCategoryEnabled(project)) {
    return null;
  }

  return (
    <BlockWithLabel
      id="subCategory"
      label="Sub Category"
      {...threeBlocksProps}
      Component={getSectionText(
        pathOrNothingUI(['subCategory', 'name'])(project),
      )}
    />
  );
}

ProjectSubCategoryInfoRow.propTypes = {
  project: shape({
    subCategory: shape({ name: string }),
    client: shape({ configs: shape({ enableProjectSubCategory: bool }) }),
  }),
};

// isPropertyLocationHierarchyEnabled :: Project -> Boolean
const isPropertyLocationHierarchyEnabled = pathEqLegacy(
  ['client', 'enablePropertyLocationHierarchy'],
  true,
);

// getNotRMValue :: Project -> String
export const getNotRMValue = R.ifElse(
  isRMProject,
  R.always('Off'),
  R.always('On'),
);

// getAlarmInfoByProp :: String -> Project -> String
const getAlarmInfoByProp = (prop) =>
  R.pathOr(NOTHING_UI_STRING, ['property', 'alarmInfo', prop]);

// getLockboxCode :: Project -> String
const getLockboxCode = R.pathOr(NOTHING_UI_STRING, [
  'property',
  'buildingInfo',
  'lockBoxCode',
]);

// getPreventativeRepairChildProject :: Project -> Project
const getPreventativeRepairChildProject = R.path([
  'preventativeRepair',
  'childProject',
]);

// showCallbackRow :: Project -> Boolean
const showCallbackRow = R.either(
  R.complement(R.path(['parent', '_id'])),
  R.compose(R.complement(isNilOrEmpty), R.prop('callbacks')),
);

export function ProjectSidebarDetails({ project }) {
  const [showCreditHours, setShowCreditHours] = useState(false);

  const {
    propertyFields,
    clientHourlyRate,
    clientMonthlyFee,
    supplierHourlyRate,
    supplierMonthlyFee,
    extraHoursClientRate,
    extraHoursSupplierRate,
  } = getHousekeepingProjectDetails(project);

  const isBid = project.type === ProjectType.BID;
  const isFee = project.type === ProjectType.FEE;
  const isWO = project.type === ProjectType.WORK_ORDER;
  const isHousekeeping = project.type === ProjectType.HOUSEKEEPING;
  const isNotFeeOrHousekeeping = !isFee && !isHousekeeping;
  const isWOBid = isWO || isBid;
  const isCloneFromProject = !!project.cloneFromProject;
  const isAlarmInfo = !!propertyFields.alarmInfo;
  const weeksNumber = getHousekeepingWeekCountByProject(project);

  const preventativeRepairChildProject =
    getPreventativeRepairChildProject(project);

  return (
    <SectionWrapper>
      <ProjectDetailsRow project={project} />
      <SubPropertiesLinks project={project} />
      <SidebarRow {...threeBlocksWrapperProps}>
        {preventativeRepairChildProject?._id && (
          <CloneProjectRow cloneFromProject={preventativeRepairChildProject} />
        )}
        {!preventativeRepairChildProject?._id && isCloneFromProject && (
          <CloneProjectRow cloneFromProject={project.cloneFromProject} />
        )}
        {!isFee && <ServiceType project={project} />}
        <ProjectSpendType project={project} />
        {isNotFeeOrHousekeeping && (
          <ProjectAmount
            isBid={isBid}
            typeProp="type"
            bidPath={['bidAmount']}
            project={project}
          />
        )}
        {isPropertyLocationHierarchyEnabled(project) && (
          <BlockWithLabel
            {...threeBlocksProps}
            id="property-location"
            label="Property Location"
            Component={getSectionText(
              R.pathOr('Other', ['propertyLocation', 'path'])(project),
            )}
          />
        )}
        {isFee && <FeeType project={project} propPath={['feeType']} />}
        {isWOBid && (
          <ClientReferenceComp id="client-reference" project={project} />
        )}
        {isSubCategoryEnabled(project) && (
          <NotRMComponent id="not-rm" text={getNotRMValue(project)} />
        )}
        <ProjectSubCategoryInfoRow project={project} />
        {isChildRecurringProject(project) && (
          <ChildRecurringProjectRow parent={project.parent} />
        )}
        <BlockWithLabel
          id="problem-code"
          label="Problem Code"
          {...threeBlocksProps}
          Component={getSectionText(
            pathOrNothingUI(['problemCode', 'name'])(project),
          )}
        />
        {isAlarmInfo && (
          <>
            <BlockWithLabel
              id="alarm-code"
              label="Alarm code"
              {...threeBlocksProps}
              Component={getSectionText(
                getAlarmInfoByProp('alarmCode')(project),
              )}
            />
            <BlockWithLabel
              id="gate-code"
              label="Gate code"
              {...threeBlocksProps}
              Component={getSectionText(
                getAlarmInfoByProp('gateCode')(project),
              )}
            />
            <BlockWithLabel
              id="lockbox-code"
              label="Lockbox code"
              {...threeBlocksProps}
              Component={getSectionText(getLockboxCode(project))}
            />
          </>
        )}
        {isHousekeeping && (
          <>
            <MonthlyHousekeepingRow
              clientMonthlyFee={clientMonthlyFee}
              supplierMonthlyFee={supplierMonthlyFee}
              clientId="housekeeping-client"
              supplierId="housekeeping-supplier"
            />
            <PorterHourlyRateRow
              project={project}
              weeksNumber={weeksNumber}
              clientHourlyRate={clientHourlyRate}
              supplierHourlyRate={supplierHourlyRate}
              setShowCreditHours={setShowCreditHours}
            />
            {showCreditHours && (
              <CreditPorterHoursForm
                projectId={project._id}
                creditedPorterHours={getCreditedPorterHours(project)}
                ticketsTotalHours={getTicketsTotalHours(project)}
                onCancel={() => setShowCreditHours(false)}
              />
            )}
            <ExtraPorterRow
              weeksNumber={weeksNumber}
              extraHoursClientRate={extraHoursClientRate}
              extraHoursSupplierRate={extraHoursSupplierRate}
            />
          </>
        )}
      </SidebarRow>
      {project.type === ProjectType.LISTING && (
        <SidebarRow {...threeBlocksWrapperProps}>
          <BlockWithLabel
            {...threeBlocksProps}
            id="bpoListPrice"
            label={LISTING_LABELS.bpoListPrice}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'bpoListPrice'],
                formatTotal,
              )(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="listPrice"
            label={LISTING_LABELS.listPrice}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'listPrice'],
                formatTotal,
              )(project),
            )}
          />
          <BlockWithLabel
            id="underContractPrice"
            {...threeBlocksProps}
            label={LISTING_LABELS.underContractPrice}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'underContractPrice'],
                formatTotal,
              )(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="bpoHigh"
            label={LISTING_LABELS.bpoHigh}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'bpoHigh'],
                formatTotal,
              )(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="bpoLow"
            label={LISTING_LABELS.bpoLow}
            Component={getSectionText(
              applyPathOrNothingUI(['listing', 'bpoLow'], formatTotal)(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="bpoDate"
            label={LISTING_LABELS.bpoDate}
            Component={getSectionText(
              applyPathOrNothingUI(['listing', 'bpoDate'], formatDate)(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="salesAddendumDate"
            label={LISTING_LABELS.salesAddendumDate}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'salesAddendumDate'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            {...threeBlocksProps}
            id="listAgreementExp"
            label={LISTING_LABELS.listAgreementExp}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'listAgreementExp'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            id="listDate"
            {...threeBlocksProps}
            label={LISTING_LABELS.listDate}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'listDate'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            id="underContractDate"
            {...threeBlocksProps}
            label={LISTING_LABELS.underContractDate}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'underContractDate'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            id="dueDiligenceExp"
            {...threeBlocksProps}
            label={LISTING_LABELS.dueDiligenceExp}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'dueDiligenceExp'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            id="expectedCloseDate"
            {...threeBlocksProps}
            label={LISTING_LABELS.expectedCloseDate}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'expectedCloseDate'],
                formatDate,
              )(project),
            )}
          />
          <BlockWithLabel
            id="referralFee"
            {...threeBlocksProps}
            label={LISTING_LABELS.referralFee}
            Component={getSectionText(
              applyPathOrNothingUI(
                ['listing', 'referralFee'],
                formatTotal,
              )(project),
            )}
          />
        </SidebarRow>
      )}
      {showCallbackRow(project) && (
        <SidebarRow {...threeBlocksWrapperProps}>
          <ProjectCallback {...project} />
          <RecallReferenceProject {...project} />
          <ProjectCallbacksLinks {...project} />
        </SidebarRow>
      )}
    </SectionWrapper>
  );
}

ProjectSidebarDetails.propTypes = {
  project: shape({
    _id: string.isRequired,
    type: string.isRequired,
    parent: shape({ _id: string.isRequired }),
  }),
};
