import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { DESC_SORT_ORDER, PRINT_TYPES } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing';
import { commonSortQuery } from '@poly/client-utils';

import {
  projectStatusesUI,
  projectStatusesColors,
} from '../../core/constants/projects.js';
import { tablePropTypes } from './ProjectsTable.js';
import { ITEMS_LIMIT } from '../../core/constants/print.js';
import { TablePrintBtn } from '../../core/tableToolbar/TablePrintPDFBtn.js';
import { recurringQueriesByEndDate } from '../../tabs/common.js';
import {
  useMyRecurringProjects,
  useProjectsBySearch,
} from '../../core/hooks/projects/index.js';

const projectsFromProps = R.pathOr([], ['searchProjects', 'hits']);

const myMasterProjectsFromProps = R.pathOr(
  [],
  ['me', 'searchManagerRecurringProjects', 'hits'],
);

const defaultProjectsSort = commonSortQuery(['startDate'])(DESC_SORT_ORDER);

const projectsByPrioritySort = commonSortQuery(['priorityId'])(DESC_SORT_ORDER);

// getQueryNameByTab :: String -> String
const getQueryNameByTab = R.compose(R.toUpper, R.replace('-', '_'));

// showWarningModal :: (getItems func, query result) -> Bool
export const showWarningModal = (itemsFromProps, data) =>
  R.compose(R.gt(R.__, ITEMS_LIMIT), R.prop('length'), itemsFromProps)(data);

const useMapPropsForQuery = (queries, defaultTab) => {
  const routerQuery = useRouterQuery(['tab']);

  const tab = routerQuery.tab || defaultTab;
  const queryName = getQueryNameByTab(tab);

  return {
    query: queries[queryName],
    metaData: {
      status: {
        text: projectStatusesUI[queryName],
        color: projectStatusesColors[queryName],
      },
    },
    sort: defaultProjectsSort,
  };
};

const useProjectsPrintLogic = ({
  result,
  printType,
  metaData = {},
  itemsFromProps,
}) => ({
  printType,
  data: itemsFromProps(result),
  metaData: { title: 'Projects', ...metaData },
  showWarningModal: showWarningModal(itemsFromProps, result),
});

export function RecalledProjectsPrintPDFBtn({ query }) {
  const { result } = useProjectsBySearch({
    query,
    sort: projectsByPrioritySort,
  });

  const printTableProps = useProjectsPrintLogic({
    result,
    itemsFromProps: projectsFromProps,
    metaData: { title: 'Recalled Projects' },
    printType: PRINT_TYPES.RECALLED_PROJECTS,
  });

  return <TablePrintBtn {...printTableProps} />;
}

RecalledProjectsPrintPDFBtn.propTypes = tablePropTypes;

export function MyMasterProjectsPrintPDFBtn({ defaultTab }) {
  const { query, metaData, sort } = useMapPropsForQuery(
    recurringQueriesByEndDate,
    defaultTab,
  );

  const { result } = useMyRecurringProjects({ sort, query });

  const printTableProps = useProjectsPrintLogic({
    result,
    metaData,
    printType: PRINT_TYPES.PROJECTS,
    itemsFromProps: myMasterProjectsFromProps,
  });

  return <TablePrintBtn {...printTableProps} />;
}

MyMasterProjectsPrintPDFBtn.propTypes = {
  defaultTab: string,
};
