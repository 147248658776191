export const WorkOrderPriority = {
  PLANNED: 'b_plannedPriority',
  NORMAL: 'c_normalPriority',
  URGENT: 'd_urgentPriority',
  EMERGENCY: 'e_emergencyPriority',
};

export const ProjectPrioritiesUIMap = {
  [WorkOrderPriority.EMERGENCY]: 'Emergency',
  [WorkOrderPriority.PLANNED]: 'Planned',
  [WorkOrderPriority.URGENT]: 'Urgent',
  [WorkOrderPriority.NORMAL]: 'Normal',
};

export const ClientProjectPriorityUnit = {
  HOURS: 'hours',
  DAYS: 'days',
};

export const ClientProjectPriorityExpirationType = {
  ADD: 'addProjectPriorityExpirationType',
  SET: 'setProjectPriorityExpirationType',
};

const { NORMAL, URGENT, EMERGENCY } = WorkOrderPriority;

export const DEFAULT_CLIENT_PROJECT_PRIORITIES = [
  {
    id: NORMAL,
    name: ProjectPrioritiesUIMap[NORMAL],
    unit: ClientProjectPriorityUnit.DAYS,
    amount: 5,
    expirationDateConfig: {
      type: ClientProjectPriorityExpirationType.ADD,
      // add 1 day
      value: { hours: 24, minutes: 0, seconds: 0 },
    },
  },
  {
    id: URGENT,
    name: ProjectPrioritiesUIMap[URGENT],
    unit: ClientProjectPriorityUnit.HOURS,
    amount: 24,
    expirationDateConfig: {
      type: ClientProjectPriorityExpirationType.SET,
      // set 17:00 day of current day
      value: { hours: 17, minutes: 0, seconds: 0 },
    },
  },
  {
    id: EMERGENCY,
    name: ProjectPrioritiesUIMap[EMERGENCY],
    unit: ClientProjectPriorityUnit.HOURS,
    amount: 4,
    expirationDateConfig: {
      type: ClientProjectPriorityExpirationType.ADD,
      // add 30 minutes
      value: { hours: 0, minutes: 30, seconds: 0 },
    },
  },
];
