import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { func, string, bool } from 'prop-types';
import {
  startOfDay,
  endOfDay,
  subDays,
  endOfWeek,
  endOfYear,
  endOfMonth,
  endOfToday,
  startOfWeek,
  startOfYear,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { SystemAccountTypes } from '@poly/constants';
import { ensureIsDate, alwaysNewDate, isNilOrEmpty } from '@poly/utils';
import {
  progressiveRangePickerOptions,
  progressiveOptionsToDates,
  FlexContainer,
} from '@poly/admin-ui';
import { DateRangePicker, getThemeColor, InputOutForm } from '@poly/admin-book';

import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import {
  FlexCenterAlign,
  SpaceBetween,
} from '../../components/FlexContainer.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { AccountsSelect } from '../../components/AccountsSelect.js';
import { filterAccountsBySystemType } from '../ChartOfAccounts/helper.js';
import { BankRegistersExportPDFButton } from './BankRegistersExportPDFButton.js';
import {
  bankRegistersQueryPropType,
  bankRegistersQueryDataPropType,
} from './commonPropTypes.js';
import { BankRegistersSearchInput } from './BankRegistersSearchInput.js';
import { BankRegistersExportXlsButton } from './BankRegistersExportXlsButton.js';

const SearchPageHeaderContainerS = styled(SearchPageHeaderContainer)`
  background-color: ${getThemeColor(['lightest'])};
  box-shadow: none;

  > div {
    width: 100%;
    margin: 0;

    > div {
      :first-child {
        padding: 10px 70px 10px 36px;
      }

      :last-child {
        padding: 0 70px 0 36px;
      }
    }
  }
`;

const SearchHeaderColumnS = styled(SearchHeaderColumn)`
  flex-direction: column;
  align-items: inherit;
  margin: 0 15px 0 0;

  label {
    font-weight: inherit;
    font-size: 10px;
    color: ${getThemeColor(['midDark'])};
    margin-bottom: 8px;
  }
`;

const SearchHeaderButtonS = styled(SearchHeaderButton)`
  min-width: auto;
  padding: 3px 14px;
`;

const SpaceBetweenS = styled(SpaceBetween)`
  align-items: flex-end;
  margin-bottom: 20px;
`;

const ExportButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 75px;
  right: 145px;

  @media (min-width: 1440px) {
    margin-right: calc(50% - 720px);
  }
`;

const customDateRangeButtons = {
  TODAY: 'Today',
  LAST_24_HOURS: 'Last 24 hours',
  THIS_WEEK: 'This week',
  LAST_7_DAYS: 'Last 7 days',
  THIS_MONTH: 'This month',
  LAST_30_DAYS: 'Last 30 days',
  LAST_90_DAYS: 'Last 90 days',
  THIS_YEAR: 'This year',
};

const customDateRangeToButtons = {
  [customDateRangeButtons.TODAY]: {
    from: startOfToday(),
    to: endOfToday(),
  },
  [customDateRangeButtons.LAST_24_HOURS]: {
    from: subDays(alwaysNewDate(), 1),
    to: alwaysNewDate(),
  },
  [customDateRangeButtons.THIS_WEEK]: {
    from: startOfWeek(alwaysNewDate()),
    to: endOfWeek(alwaysNewDate()),
  },
  [customDateRangeButtons.LAST_7_DAYS]: {
    from: subDays(alwaysNewDate(), 7),
    to: alwaysNewDate(),
  },
  [customDateRangeButtons.THIS_MONTH]: {
    from: startOfMonth(alwaysNewDate()),
    to: endOfMonth(alwaysNewDate()),
  },
  [customDateRangeButtons.LAST_30_DAYS]: {
    from: subDays(alwaysNewDate(), 30),
    to: alwaysNewDate(),
  },
  [customDateRangeButtons.LAST_90_DAYS]: {
    from: subDays(alwaysNewDate(), 90),
    to: alwaysNewDate(),
  },
  [customDateRangeButtons.THIS_YEAR]: {
    from: startOfYear(alwaysNewDate()),
    to: endOfYear(alwaysNewDate()),
  },
};

// bankRegistersFilters :: BankRegistersFilters
const bankRegistersFilters = {
  accountId: 'accountId',
  datePreset: 'datePreset',
  fromDate: 'fromDate',
  toDate: 'toDate',
  searchTerm: 'searchTerm',
};

const bankRegistersHeaderConfig = [
  {
    name: bankRegistersFilters.datePreset,
    nestedFields: ['startDate', 'endDate', 'option'],
    defaultValue: {
      ...progressiveOptionsToDates[progressiveRangePickerOptions.THIS_MONTH],
      option: progressiveRangePickerOptions.THIS_MONTH,
    },
  },
  {
    name: bankRegistersFilters.accountId,
  },
  {
    name: bankRegistersFilters.searchTerm,
    // prevents uncontrolled input component warning from React
    defaultValue: '',
  },
];

// dissocPropIfEmpty :: k -> {k: Any} -> {k: Any}
//   k = String
const dissocPropIfEmpty = (prop) =>
  R.when(R.propSatisfies(R.isEmpty, prop), R.dissoc(prop));

// constructAndAdjustDate :: (Date -> Date, String) -> Object -> Object
const constructAndAdjustDate = (adjustDateFn, prop) =>
  R.over(
    R.lensProp(prop),
    R.when(R.is(String), R.compose(adjustDateFn, R.constructN(1, Date))),
  );

// generateSearchBankRegistersQuery :: BankRegistersFilters -> BankRegistersFilters
const generateSearchBankRegistersQuery = R.compose(
  constructAndAdjustDate(R.compose(startOfDay, ensureIsDate), 'fromDate'),
  constructAndAdjustDate(R.compose(endOfDay, ensureIsDate), 'toDate'),
  dissocPropIfEmpty('searchTerm'),
  dissocPropIfEmpty('accountId'),
  R.applySpec({
    accountId: R.prop('accountId'),
    searchTerm: R.prop('searchTerm'),
    fromDate: R.path(['datePreset', 'startDate']),
    toDate: R.path(['datePreset', 'endDate']),
  }),
  R.defaultTo({}),
);

export function BankRegistersHeader({
  title,
  query,
  setQuery,
  systemAccountType,
  exportTitle,
  hideCheckNumber,
  loading,
  data,
}) {
  const onSearchHandler = (values) => {
    if (values.accountId) {
      setQuery(generateSearchBankRegistersQuery(values));
    }
  };

  const { searchFilters, handlers, onReset, onSearch } = useSearchFilters(
    bankRegistersHeaderConfig,
    onSearchHandler,
    true,
  );

  const onResetSearch = () => {
    onReset();
    setQuery(null);
  };

  const onSearchTermChange = (searchTerm) => {
    onSearchHandler({ ...searchFilters, searchTerm });
    handlers.searchTerm(searchTerm);
  };

  const disabled = isNilOrEmpty(data);

  return (
    <SearchPageHeaderContainerS title={title} height="134px">
      <ExportButtonWrapper>
        <BankRegistersExportPDFButton
          exportTitle={exportTitle}
          query={query}
          hideCheckNumber={hideCheckNumber}
          data={data}
          disabled={disabled}
        />
        <BankRegistersExportXlsButton
          exportTitle={exportTitle}
          query={query}
          hideCheckNumber={hideCheckNumber}
          disabled={disabled}
          data={data}
        />
      </ExportButtonWrapper>
      <FiltersContainer>
        <SpaceBetweenS>
          <FlexContainer>
            <SearchHeaderColumnS
              filterWidth="230px"
              title={
                systemAccountType === SystemAccountTypes.BANK_ACCOUNT
                  ? 'Bank Account'
                  : 'Credit Card'
              }
            >
              <InputOutForm
                Component={AccountsSelect}
                requiredErr={`${
                  systemAccountType === SystemAccountTypes.BANK_ACCOUNT
                    ? 'Bank Account'
                    : 'Credit Card'
                } is required`}
                name="accountId"
                value={searchFilters.accountId}
                onChange={handlers.accountId}
                filterAccounts={filterAccountsBySystemType(systemAccountType)}
                width="100%"
                extractValue={R.prop('_id')}
                required
                selectedOptionAtOnChange
              />
            </SearchHeaderColumnS>
            <SearchHeaderColumnS title="Preset" filterWidth="auto">
              <DateRangePicker
                width="auto"
                value={searchFilters.datePreset}
                onChange={handlers.datePreset}
                customRangeButtons={customDateRangeButtons}
                getCustomRangeToButtons={() => customDateRangeToButtons}
              />
            </SearchHeaderColumnS>
          </FlexContainer>
          <SearchHeaderColumnS titleWidth="0px" filterWidth="auto">
            <FlexCenterAlign>
              <BankRegistersSearchInput
                name="search_term"
                value={searchFilters.searchTerm}
                onChange={onSearchTermChange}
              />
              <SearchHeaderButtonS
                size="tiny"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButtonS>
              <SearchHeaderButtonS
                size="tiny"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButtonS>
            </FlexCenterAlign>
          </SearchHeaderColumnS>
        </SpaceBetweenS>
      </FiltersContainer>
    </SearchPageHeaderContainerS>
  );
}

BankRegistersHeader.defaultProps = {
  systemAccountType: SystemAccountTypes.BANK_ACCOUNT,
};

BankRegistersHeader.propTypes = {
  query: bankRegistersQueryPropType,
  setQuery: func.isRequired,
  systemAccountType: string,
  title: string.isRequired,
  exportTitle: string.isRequired,
  hideCheckNumber: bool,
  loading: bool,
  data: bankRegistersQueryDataPropType,
};
