import { gql } from '@apollo/client';

import { clientFieldsForFilters } from './fragments.js';

export const CLIENTS_BY_SEARCH = gql`
  query CLIENTS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchClients(input: $searchInput) {
      hits {
        ...clientFieldsForFilters
        address {
          formatted_address
          address_parts {
            street_number
            postal_code
            administrative_area_level_1
            route
            locality
          }
        }
        searchProperties(input: { size: 0 }) {
          total
        }
        nickName
        status
      }
      total
    }
  }

  ${clientFieldsForFilters}
`;

const clientDetailsCommonFields = gql`
  fragment clientDetailsCommonFields on Client {
    status
    apps
    cardNumber
    nickName
    enableReportOnlyProjectType
    enablePropertyLocationHierarchy
    enableGlCodes
    enableCostCenter
    configs {
      defaultProjectType
      enableHousekeepingProjectType
      enableProjectSubCategory
      enablePurchaseOrder
      notifications {
        serviceRequestConfirmationEmail {
          toSiteContact
          toRequester
          toClientManager
        }
      }
      projectPriorities {
        id
        name
        amount
        unit
      }
    }
    financial {
      costCenter
      division {
        _id
        code
        name
      }
      exemptSalesTax
    }
  }
`;

export const CLIENT_DETAILS = gql`
  query CLIENT_DETAILS($clientId: ID!) {
    client(id: $clientId) {
      ...clientFieldsForFilters
      ...clientDetailsCommonFields
    }
  }

  ${clientFieldsForFilters}
  ${clientDetailsCommonFields}
`;

export const CLIENT_AND_PROPERTY_DETAILS = gql`
  query CLIENT_AND_PROPERTY_DETAILS($clientId: ID!, $propertyId: ID!) {
    client(id: $clientId) {
      ...clientFieldsForFilters
      ...clientDetailsCommonFields
    }

    property(id: $propertyId) {
      _id
      costCenter
      isMaster
    }
  }

  ${clientFieldsForFilters}
  ${clientDetailsCommonFields}
`;
